import * as React from 'react'
import clsx from 'clsx'
import {normalizeString} from "Trunkrs-SDK/dist/utils/normalizeString";
import { eventHandler, isTrunkrsNr } from '../../../../../utils'
import {
  AppContext,
  AuthContext,
  RouterContext
} from '../../../../store/context'
import { Translate } from '../../../../base/components'

import './style.scss'

export const LoginForm = () => {
  const { loading, setLoading } = React.useContext(AppContext)
  const { authenticate, getTrunkrsNrViaBarcode } = React.useContext(AuthContext)
  const {
    history,
    match: {
      params: { barcode }
    }
  } = React.useContext(RouterContext)
  const [hasErrors, setHasErrors] = React.useState(false)
  const [trunkrsNr, setTrunkrsNr] = React.useState('')
  const [postalCode, setPostalCode] = React.useState('')

  React.useEffect(() => {
    if (barcode) {
      getTrunkrsNrViaBarcode(barcode).then(
        (response: string) => response && setTrunkrsNr(response)
      )
    }
  }, [getTrunkrsNrViaBarcode]) // eslint-disable-line react-hooks/exhaustive-deps

  const {
    APP_NAME,
    TRUNKRS_NR,
    POSTAL_CODE,
    SEARCH,
    SEARCH_DESC,
    LOGIN_ERR_MSG_1,
    LOGIN_ERR_MSG_3,
    VISIT_WEBSITE
  } = new Translate({}).getTranslation([
    'APP_NAME',
    'TRUNKRS_NR',
    'POSTAL_CODE',
    'SEARCH',
    'SEARCH_DESC',
    'CANT_FIND',
    'LOGIN_ERR_MSG_1',
    'LOGIN_ERR_MSG_3',
    'CONTACT_US',
    'VISIT_WEBSITE'
  ])

  const login = async () => {
    setLoading(true)
    let nextTrunkrsNr = trunkrsNr
    if (!isTrunkrsNr(nextTrunkrsNr)) {
      nextTrunkrsNr = await getTrunkrsNrViaBarcode(nextTrunkrsNr)
    }
    const authenticated = await authenticate(
      nextTrunkrsNr,
      normalizeString(postalCode)
    )
    if (authenticated) {
      history.push(`/${nextTrunkrsNr}/${normalizeString(postalCode)}`)
    } else {
      setHasErrors(true)
    }
    setLoading(false)
  }

  const handleKeyDown = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    const keyCode = ev.keyCode || ev.which

    if (keyCode === 13) {
      login()
    } else if (hasErrors) {
      setHasErrors(false)
    }
  }

  return (
    <div className="row align-self-center h-75">
      <div className="col-md-2 d-none d-md-block" />
      <div className="col-md-8">
        <div className="box d-flex justify-content-center">
          <div className="login-form">
            <div className="top-wrapper">
              <div className="login-header">{`${APP_NAME}`}</div>
              <div className="login-subheader">{SEARCH_DESC}</div>
              <div className="wrapper">
                <input
                  className={clsx('input-holder', { 'has-error': hasErrors })}
                  onChange={eventHandler(setTrunkrsNr)}
                  onKeyDown={handleKeyDown}
                  value={trunkrsNr}
                  placeholder={TRUNKRS_NR}
                  disabled={loading}
                />
                <br />
                <input
                  className={clsx('input-holder', { 'has-error': hasErrors })}
                  onChange={eventHandler(setPostalCode)}
                  onKeyDown={handleKeyDown}
                  value={postalCode}
                  placeholder={POSTAL_CODE}
                  disabled={loading}
                />
                <br />
                <button
                  onClick={login}
                  className={clsx('flat-btn green w-50', { disabled: loading })}
                >
                  {SEARCH}
                </button>
              </div>
              {hasErrors && (
                <div className="error help-info">
                  <p>
                    {LOGIN_ERR_MSG_1}
                    <a
                      href="https://trunkrs.nl/klantenservice/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {LOGIN_ERR_MSG_3}
                    </a>
                  </p>
                </div>
              )}
            </div>
            <div className="bottom-wrapper d-flex align-items-end align-items-md-center">
              <div className="d-flex flex-column w-100">
                <div className="ext-site-m d-md-none d-lg-none">
                  <a
                    href="https://trunkrs.nl/"
                    className="trunkrs-redirect align-self-center"
                  >
                    {VISIT_WEBSITE}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-2 d-none d-md-block" />
    </div>
  )
}
