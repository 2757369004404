import { INIT_URL, ORDER_REF, ROOT_URL } from '../../constants'

import { Home } from '../scenes/Home'
import { Login } from '../scenes/Login'

import { IRoute } from '.'

const routes: IRoute[] = [
  {
    path: INIT_URL,
    exact: true,
    component: Login
  },
  {
    path: ORDER_REF,
    exact: true,
    component: Login
  },
  {
    path: ROOT_URL,
    exact: true,
    component: Home
  }
]

export default routes
