import * as React from 'react'
import clsx from 'clsx'

import { IParcelState } from 'Trunkrs-SDK/dist/models/communications/TrackAndTalk'
import { ITrackTraceInformation } from 'Trunkrs-SDK/dist/models/communications'

import { isDelivered } from '../../../../../utils'
import { SHIPMENT_STATES } from '../../../../../constants'

import { ShipmentContext } from '../../../..//store/context'

import { Confetti } from '../../../../base/components'
import { ParcelETAInfo } from './components/ParcelETAInfo'
import { ParcelInfo } from './components/ParcelInfo'
import { ParcelStatus } from './components/ParcelStatus'

import './deliveryinformation.scss'

export const DeliveryInformation = (props: { headerExpanded: boolean }) => {
  const { headerExpanded } = props
  const { shipmentInfo } = React.useContext(ShipmentContext)
  const { parcel } = shipmentInfo as ITrackTraceInformation

  return (
    <div
      id="delivery-info"
      className={clsx('delivery-info row no-gutters h-100', {
        'on-top': headerExpanded
      })}
    >
      <div id="parcel-info-container" className="col-4">
        <div className="d-flex flex-column align-items-center">
          <ParcelInfo headerExpanded={headerExpanded} />
          <ParcelStatus />
        </div>
      </div>
      <ParcelETAInfo />
      {parcel &&
        isDelivered(
          SHIPMENT_STATES[(parcel.state as IParcelState).name],
          false
        ) && <Confetti />}
    </div>
  )
}
