import { useState } from 'react'
import * as Sentry from '@sentry/browser'

import { LANGUAGES, TRUNKRS_DEFAULT_LANG } from '../../../constants'
import { IAppState } from './state'

export const useAppActions = (initialState: IAppState) => {
  const [loading, setLoading] = useState(initialState.loading)
  const [language, setLanguage] = useState(initialState.language)

  const setUserLanguage = (lang: LANGUAGES) => {
    if (lang in LANGUAGES) {
      if (localStorage) {
        try {
          localStorage.setItem(TRUNKRS_DEFAULT_LANG, lang)
        } catch (error) {
          const isQuotaExceeded =
            error.name === 'QuotaExceededError' ||
            error.name === 'QUOTA_EXCEEDED_ERR' ||
            error.name === 'NS_ERROR_DOM_QUOTA_REACHED'

          if (!isQuotaExceeded) {
            Sentry.captureException(error)
          }
        }
      }

      setLanguage(lang)
    }
  }

  return {
    loading,
    setLoading,
    language,
    setUserLanguage
  }
}
