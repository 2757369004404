import * as React from 'react'

import { LANGUAGES } from '../../../../constants'
import { log } from '../../../../utils'

interface ITranslateProps {
  children: string
}

const getCurrentLang = () => {
  return LANGUAGES.DUTCH
  // from old implementation, for enhancement
}

export class Translate extends React.Component<ITranslateProps | {}, {}> {
  public static capitalize(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  public static translate(key: string): string {
    const keys = require(`./i18/${getCurrentLang()}`)

    if (typeof key === 'string' && keys.default) {
      return keys && keys.default[key] ? keys.default[key] : key
    }

    return ''
  }

  public getKeys(): {} {
    try {
      const keys = require(`./i18/${getCurrentLang()}`)
      return keys.default
    } catch (err) {
      // tslint:disable-next-line
      log('ERROR', `Error opening the translation file [${getCurrentLang()}]`)
      return err
    }
  }

  public getTranslation(key: string | string[]): { [s: string]: string } {
    const keys = this.getKeys()
    if (typeof key === 'string') {
      return keys && keys[key] ? keys[key] : key
    }
    return key.reduce((parsed: {}, item: string): {} => {
      Object.assign(parsed, {
        [item]: keys && keys[item] ? keys[item] : item
      })
      return parsed
    }, {})
  }

  public render() {
    const { children } = this.props
    let key = ''
    if (children && typeof children === 'string') {
      key = children
    }
    return <span>{this.getTranslation(key)}</span>
  }
}
