import { useState } from 'react'
import { get } from 'lodash'

import API from '../../../api/v2'
import { unwrapResponse } from '../../hooks'

import { IAuthState } from './state'

export const useAuthActions = (initialState: IAuthState) => {
  const trackAndTalk = API.TrackAndTalk()
  const [authenticated, setAuthenticated] = useState(initialState.authenticated)
  const [identity, setIdentity] = useState(initialState.identity)

  const authenticate = async (trunkrsNr: string, postalCode: string) => {
    const [err] = await unwrapResponse(
      trackAndTalk.login(trunkrsNr, postalCode.toLowerCase())
    )

    if (!err) {
      setIdentity({
        trunkrsNr,
        postalCode
      })
    }

    setAuthenticated(!err)
    return !err
  }

  const getPostalCode = async (trunkrsNr: string, token: string) => {
    const [, response] = await unwrapResponse(
      trackAndTalk.getPostalCode(trunkrsNr, token)
    )
    return response
  }

  const getTrunkrsNrViaBarcode = async (barcode: string) => {
    const [, response] = await unwrapResponse(
      trackAndTalk.getTrunkrsNr(barcode)
    )

    return get(response, 'trunkrsNr', '')
  }

  return {
    identity,
    authenticate,
    authenticated,
    getPostalCode,
    getTrunkrsNrViaBarcode
  }
}
