import findLastIndex from 'lodash/findLastIndex'

import { SHIPMENT_STATE } from '../../constants'
import { IShipmentEvent } from '../../models'

export const getLastEventQueue = (events: IShipmentEvent[]) => {
  const filteredEvents = [
    ...events
      .filter(event => event.stateId !== SHIPMENT_STATE.DIRECT_DELIVERY)
      .reverse()
  ]
  const lastIndex = findLastInitialStateIndex(filteredEvents)

  if (lastIndex > 0) {
    return filteredEvents.slice(lastIndex)
  }

  return filteredEvents
}

export const findLastInitialStateIndex = (events: IShipmentEvent[]) => {
  let initialStateIndex = findLastIndex(
    events,
    event =>
      event.stateId === SHIPMENT_STATE.SCANNED_AT_TRUNKRS ||
      event.stateId === SHIPMENT_STATE.CREATED
  )

  if (initialStateIndex > 0) {
    if (events[initialStateIndex - 1].stateId === SHIPMENT_STATE.CREATED) {
      initialStateIndex--
    }
  }

  return initialStateIndex
}
