import * as React from 'react'

import { LANGUAGES } from '../../../constants'

export interface IAppState {
  loading: boolean
  language: string
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  setUserLanguage: (lang: LANGUAGES) => void
}

export const initialState: IAppState = {
  loading: false,
  language: '',
  setLoading: () => null,
  setUserLanguage: () => null
}
