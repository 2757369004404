import * as React from 'react'
import { Modal } from '../modal'

import './style.scss'

export const ModalBox = ({
  title,
  show,
  handleClose,
  children
}: {
  children: React.ReactNode
  title: string
  show: boolean
  handleClose?: (ev?: React.SyntheticEvent<any>) => void
}) => {
  const [isVisible, setIsVisible] = React.useState(show)

  const closeModal = (ev: React.MouseEvent<HTMLButtonElement>) => {
    setIsVisible(false)
    if (typeof handleClose === 'function') {
      handleClose(ev)
    }
  }

  return (
    <Modal show={isVisible}>
      <div className="modal-box modal-shadow">
        <div className="title-bar">
          <h3 className="title">{title}</h3>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={closeModal}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">{children}</div>
      </div>
    </Modal>
  )
}
