import * as React from 'react'
import moment from 'moment'
import get from 'lodash/get'
import clsx from 'clsx'

import { IProgress, IShipmentHistory } from '../../../../../../models'
import { isDelivered as checkIfDelivered } from '../../../../../../utils'
import { SHIPMENT_STATES } from '../../../../../../constants'

import { TimeTicker, Translate } from '../../../../../base/components'
import { ShipmentContext } from '../../../../../store/shipment'
import { createHistoryTemplate } from './helpers'

export const TimelineProgress = React.memo(
  ({ shipmentHistory }: { shipmentHistory: IShipmentHistory }) => {
    const { shipmentInfo } = React.useContext(ShipmentContext)
    const timelineProgress: IProgress[] = React.useMemo(
      () => createHistoryTemplate(shipmentHistory),
      [shipmentHistory]
    )

    return (
      <ul className="step-progress">
        {React.useMemo(
          () =>
            timelineProgress.map((progress, index) => {
              const sender = get(shipmentInfo, 'sender.name', '')
              const {
                label: [header, value],
                complete,
                failure: failed,
                state
              } = progress
              const timestamp = state
                ? moment(state.timestamp).toISOString()
                : ''

              return (
                <li
                  key={`parcel-status-label-${index}`}
                  className={clsx({
                    complete,
                    failed,
                    success:
                      state && checkIfDelivered(state!.name as SHIPMENT_STATES)
                  })}
                >
                  <span className="step-circle" />
                  <div className="label">
                    <p className={clsx('header', { disabled: !complete })}>
                      <Translate>{header}</Translate>
                      <TimeTicker
                        className="timestamp"
                        initialTimestamp={timestamp}
                      />
                    </p>
                    <p className={clsx('value', { disabled: !complete })}>
                      {index === 0 &&
                        `${sender || Translate.translate('THE_SENDER')} `}
                      <Translate>{value}</Translate>
                    </p>
                  </div>
                </li>
              )
            }),
          [timelineProgress, shipmentInfo]
        ) /* eslint-disable-line react-hooks/exhaustive-deps */}
      </ul>
    )
  }
)
