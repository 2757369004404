import * as Sentry from '@sentry/browser'
import * as React from 'react'

import { LANGUAGES, TRUNKRS_DEFAULT_LANG } from '../../../constants'
import { AppContext } from '../../store/app'

import { PopupBox, Translate } from '../../base/components'
import { LoginForm } from './components'

import '../../base/style'

const DEFAULT_LANG = LANGUAGES.DUTCH

export const Login = () => {
  const { setUserLanguage } = React.useContext(AppContext)

  const [
    notifyForNativeTranslate,
    setNotifyForNativeTranslate
  ] = React.useState(false)

  React.useEffect(() => {
    let defaultLang: LANGUAGES = null as any

    try {
      defaultLang =
        localStorage &&
        (localStorage.getItem(TRUNKRS_DEFAULT_LANG) as LANGUAGES)
    } catch (error) {
      Sentry.captureException(error)
    }

    if (defaultLang) {
      setUserLanguage(defaultLang)
    }
    if (navigator.language.indexOf('en') > -1 && !defaultLang) {
      setNotifyForNativeTranslate(true)
    }
  }, [setUserLanguage])

  const setLanguageToDefault = () => setUserLanguage(DEFAULT_LANG)

  const setLanguageToBrowserLanguage = () => {
    const lang = navigator.language.slice(0, 2) as LANGUAGES
    setUserLanguage(lang)
  }

  return (
    <div id="log-in-form" className="container-fluid h-100">
      <div className="h-25">
        <div className="ext-site d-flex flex-row-reverse">
          <a
            href="https://trunkrs.nl/"
            className="trunkrs-redirect align-self-center"
          >
            <Translate>VISIT_WEBSITE</Translate>
          </a>
        </div>
        <div className="logo">
          <span className="trunkrs-logo plain" />
        </div>
      </div>
      <LoginForm />
      {notifyForNativeTranslate && (
        <PopupBox
          modal={true}
          handleSubmit={setLanguageToDefault}
          handleCancel={setLanguageToBrowserLanguage}
        >
          <p className="translate-notice">Translate language to Dutch?</p>
        </PopupBox>
      )}
    </div>
  )
}
