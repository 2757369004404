export default {
  APP_NAME: 'Trunkrs Track & Talk',
  WELCOME: 'welcome',
  LOGIN: 'login',
  USERNAME: 'username',
  EMAIL: 'email address',
  PASSWORD: 'password',
  RESTORE_PASSWORD: 'forgot password?',
  SEND_EMAIL: 'Reset password',
  SEND_EMAIL_MSG:
    "Fill in your e-mail address and we'll send you a new password you can use to login.",
  EMAIL_SENT_MSG:
    "We've sent a new password to your E-mail address. Once you login, using your new password, you can change your password on your profile page.",
  BACK: 'Back',
  LOGOUT: 'logout',
  ADDRES: 'Address',
  HOUSE_NUMBER: 'number',
  POSTAL_CODE: 'Postalcode',
  SEARCH_TEXT: 'Welcome to',
  SEARCH_DESC: 'Keeping track of your parcel has never been easier!',
  TRUNKRS_NR: 'Trunkrs number',
  CANT_FIND: "Help, I can't find my shipment",
  CONTACT_US:
    'Please contact us using the live chat in the bottom-right corner',
  SEARCH: 'Search',
  SEARCH_ERR:
    "We can't seem you find your shipment based on your input. Please double check your input and try again.",
  CITY: 'city',
  COUNTRY: 'country',
  STATE: 'state',
  TOUR: 'tour',
  DRIVER: 'driver',
  THE_NETHERLANDS: 'The Netherlands',
  GERMANY: 'Germany',
  TYPE_MSG: 'Ask any question...',
  ETA: 'Arrival time',
  SETTING_NOTIFICATIONS: 'Desktop notifications',
  SETTING_PRIORITY: 'Important messages only',
  LOADING: 'One moment please',
  LOADING_MSG1: 'Moving mountains just for you!',
  LOADING_MSG2: "Six banana's + nine gummy bears = your parcel!",
  LOADING_MSG3: 'Blink three time and your parcel will be found, try it out!',
  LOADING_MSG4: 'A B C D E F G H I J K L M N O Parcel',
  LOADING_MSG5: 'Oh parcel, where art thou?',
  LOADING_MSG6: 'Satelites are being repositioned to localise your parcel',
  LOADING_MSG7: 'Plata o parcel?!',
  LOADING_MSG8: 'Uw zending wordt opgehaald.',
  LOADING_MSG9: 'Uw zending wordt opgehaald.',
  LOADING_MSG10: 'Uw zending wordt opgehaald.',
  FEEDBACK_PLACEHOLDER: 'why?',
  PUSH_ENABLED_TITLE: 'Subscribed',
  PUSH_ENABLED_BODY: 'Your subscribed for push notifications',
  STATE_1: 'In warehouse',
  STATE_2: 'In warehouse',
  STATE_3: 'In warehouse',
  STATE_4: 'In warehouse',
  STATE_5: 'In transit',
  STATE_6: 'In transit',
  STATE_7: 'Almost there!',
  STATE_8: 'In warehouse',
  STATE_9: 'In warehouse',
  STATE_10: 'Delivered',
  STATE_11: 'Delivered to neighbour',
  STATE_12: 'Delivery failed :(',
  STATE_13: 'In transit',
  STATE_14: 'In transit',
  STATE_15: 'In transit',
  STATE_16: 'In transit',
  STATE_17: 'IShipment has been cancelled',
  STATE_18: 'In transit',
  UT_NEXT: 'Next',
  UT_FINISH: 'Finish',
  UT_1_TITLE: 'Welcome',
  UT_1_TEXT: 'Here you can see what time you can expect your parcel.',
  UT_2_TITLE: 'Messages from your parcel',
  UT_2_TEXT: 'You can speak to your parcel directly and give feedback',
  UT_3_TITLE: 'Go on!',
  UT_3_TEXT: 'Go ahead, ask something!',
  LOGIN_ERR_MSG_1:
    "Can't find your parcel? If you are sure that your combination of both the Trunkrs Number and the Zip Code is correct and you can still not find your parcel, then please get in ",
  LOGIN_ERR_MSG_2: '',
  LOGIN_ERR_MSG_3: 'contact with us.',
  VISIT_WEBSITE: 'Visit Website',
  EXPECTED: 'Expected',
  IN_WAREHOUSE: 'In warehouse',
  ON_THE_WAY: 'On the way ',
  ALMOST_THERE: 'Close to you',
  DELIVERED: 'Delivered',
  DELIVERED_TO_NEIGHBOUR: 'Delivered to neighbour',
  NOT_DELIVERED: 'Not delivered',
  CANCELED: 'Shipment has been canceled',
  RETURNED_TO_SENDER:
    'Your shipment has been cancelled, please contact the sender.',
  THE_SENDER: 'The sender',
  DELIVERY: 'Delivery',
  TODAY: 'Today',
  TOMORROW: 'Tomorrow',
  RECIPIENT: 'Recipient',
  SENDER: 'Sender',
  EXPECTED_DELIVERY: 'Expected Delivery',
  CANCELED_DELIVERY_TRUNKRS: 'The shipment has been canceled by Trunkrs.',
  CANCELED_DELIVERY_SENDER: 'The shipment has been canceled by the merchant.',
  AT_YOUR_NEIGHBOURS: 'Delivered At',
  ON: 'on',
  SIGN_OUT: 'Sign out',
  MADE_REQUEST_FOR_DELIVERY: 'made request for delivery of your parcel',
  PARCEL_DELIVERED_TO_WAREHOUSE:
    'Your parcel was handed over for delivery to our central warehouse',
  DRIVER_ON_THE_WAY: 'Our driver is on its way to deliver your parcel',
  DRIVER_IS_CLOSE:
    'Our driver is very close to you, expect your doorbell ringing any moment',
  PLEASE_TYPE_MESSAGE: 'Please type in capital letters:',
  TO_CONFIRM_MESSAGE: 'to conﬁrm you want your parcel to be delivered',
  POSTPONE_SUCCEEDED_MESSAGE_1: 'Great!',
  POSTPONE_SUCCEEDED_MESSAGE_2: 'We will deliver your parcel tomorrow/Monday',
  NEIGHBOUR_FORM_QUERY:
    'To which neighbour would you like us to deliver your parcel?',
  STREET: 'STREET',
  HOUSE_NR: 'HOUSE NR.',
  CITY_NEIGHBOUR_FORM: 'CITY',
  SEND: 'SEND',
  ADDRESS_CHANGE_SUCCEEDED_MESSAGE_1: 'NICE!',
  ADDRESS_CHANGE_SUCCEEDED_MESSAGE_2:
    'I have told our driver to deliver at your neighbours',
  DELIVERY_ADDRESS_LABEL: 'New Delivery Address',
  PARCEL_INFORMATION: 'Parcel information',
  TEXT_MESSAGE: 'Text message notifications',
  TEXT_MESSAGE_REG:
    'Want to be always kept in touch about your delivery? Leave your phone number and we will send you a text message for every update!',
  TEXT_MESSAGE_UPDATE:
    'You already left your phone number. Want to change the number? Simply fill out the new number and hit ‘save’ - Make sure it’s the right one',
  SAVE: 'SAVE',
  ONBOARDING_GREETING_1: 'Welcome to Trunkrs Track & Talk',
  ONBOARDING_GREETING_2:
    "Tracking your parcel has never been easier! We'd love to show you what to expect.",
  ONBOARDING_STATUS_1: 'Keep track of your parcel',
  ONBOARDING_STATUS_2:
    "Our live status tells you what happened to your parcel and what is about to happen to it until it's in your hands.",
  ONBOARDING_TIME_1: 'Always up-to-date delivery times',
  ONBOARDING_TIME_2:
    "Unnecessary waiting makes nobody happy. So we won't let you wait if it's not needed. Our delivery times are as accurate as possible, so you can spend your time on better things.",
  ONBOARDING_CHAT_1: 'Our delivery assistant is happy to help you',
  ONBOARDING_CHAT_2:
    'Got a question? Rather want your parcel to be delivered at your neighbours? Or anything else we can help you with? Ask our delivery assistant!',
  ONBOARDING_SMS_1: 'Stay up to date with text message notifications',
  ONBOARDING_SMS_2:
    "Leave your phone number and always stay up to date your parcel, even if you're not able to check out this page.",
  ONBOARDING_BACK: 'Back',
  ONBOARDING_NEXT: 'Next',
  ONBOARDING_READY: 'I am ready!',
  MORE: 'More...',
  CHANGE_ADDRESS_RETRY:
    'This address is not within 200 meters of the original address. Please make sure it is.',
  TRY_AGAIN: 'Try again',
  DELAY_DELIVERY_MESSAGE:
    "It seems we are experiencing a slight delay in our delivery process. Don't worry, our driver is still on it's way!",
  NO_DELIVERY_DEFAULT:
    "Sadly we couldn't deliver your parcel tonight. We will attempt to deliver it the next business day. Sorry for the inconvenience.",
  NO_DELIVERY_RECIPIENT_NOT_HOME:
    'A moment ago we were standing at your door, but neither you or your neighbours answered. We will attempt to deliver it the next business day.',
  NO_DELIVERY_MISSORT:
    'Due to a mistake your parcel was sorted to the wrong region. We will attempt to deliver it the next business day. Our sincere apologies.',
  NO_DELIVERY_BUSINESS_CLOSED:
    'Your address is a closed business address. We will attempt to deliver it the next business day. Want to change the address? Please call: 0850601410',
  NO_DELIVERY_NO_TIME_IN_TIMESLOT:
    'Due to delays in our tour we were not able to deliver your shipment tonight. We will attempt to deliver it the next business day.',
  NO_DELIVERY_EXTREME_WEATHER:
    "Due to extreme weather conditions our driver wasn't able to deliver your parcel tonight. We will attempt to deliver it the next business day.",
  NO_DELIVERY_DRIVER_ACCIDENT:
    "Unfortunately our driver was involved in an accident and can't continue. We will attempt to deliver it the next business day.",
  NO_DELIVERY_REFUSED_BY_CUSTOMER:
    'Tonight you have refused a parcel from our driver. We are sorry to hear this. If you would like us to help you with anything, please call: 0850601410',
  NO_DELIVERY_NOT_HANDED_OVER_1:
    'Your shipment was not handed over to us for delivery by',
  NO_DELIVERY_NOT_HANDED_OVER_2:
    'We advise you to contact them, they will be able to tell you when they will hand over your shipment for delivery.',
  SHIPPING_HISTORY: 'SHIPPING HISTORY',
  CLOSE: 'CLOSE',
  SEE_SHIPPING_HISTORY: 'See shipping history',
  RETURN_TO_SENDER_NOTIFICATION:
    'We have attempted to deliver your parcel 3 times without success. Thus, we have sent it back to ',
  SHARE_PAGE: 'Share this page',
  COPY: 'copy',
  COPIED: 'copied!',
  CANCEL: 'Cancel',
  POSTAL_CODE_LABEL: 'POSTAL CODE',
  NEIGHBOUR_NAME: 'NEIGHBOUR NAME',
  NOT_ARRIVED: 'The parcel was not supplied to Trunkrs',
  HELP_US_IMPROVE: 'HELP US IMPROVE',
  HOW_DID_YOU_EXPERIENCE_OUR_SERVICE: 'How did you experience our service?',
  IT_WAS_GREAT: 'It was great',
  COULD_BE_BETTER: 'Could be better',
  THANK_YOU_FOR_HELPING: 'THANK YOU FOR HELPING!',
  WE_HAVE_SAVED_YOUR_VOTE:
    ' We have saved your vote and will use it to become better at what we do!',
  YOUR_VOTE_ABOUT_OUR_SERVICE: 'Your vote about our service:'
}
