import includes from 'lodash/includes'

import { SHIPMENT_GENERIC_STATES, SHIPMENT_STATES } from '../constants'
import { convertToGenericState } from './convertToGenericState'

export const isCanceled = (
  state: SHIPMENT_STATES | SHIPMENT_GENERIC_STATES,
  generic: boolean = true
) =>
  includes(
    generic
      ? [SHIPMENT_GENERIC_STATES.CANCELED]
      : [
          SHIPMENT_STATES.EXCEPTION_SHIPMENT_CANCELLED_BY_TRUNKRS,
          SHIPMENT_STATES.EXCEPTION_SHIPMENT_CANCELLED_BY_SENDER
        ],
    state
  )

export const isCanceledTrunkrs = (
  state: SHIPMENT_STATES | SHIPMENT_GENERIC_STATES,
  generic: boolean = true
) =>
  includes(
    generic
      ? [SHIPMENT_GENERIC_STATES.CANCELED]
      : [SHIPMENT_STATES.EXCEPTION_SHIPMENT_CANCELLED_BY_TRUNKRS],
    state
  )

export const isCanceledSender = (
  state: SHIPMENT_STATES | SHIPMENT_GENERIC_STATES,
  generic: boolean = true
) =>
  includes(
    generic
      ? [SHIPMENT_GENERIC_STATES.CANCELED]
      : [SHIPMENT_STATES.EXCEPTION_SHIPMENT_CANCELLED_BY_SENDER],
    state
  )

export const isNotArrived = (
  state: SHIPMENT_STATES | SHIPMENT_GENERIC_STATES,
  generic: boolean = true
) =>
  includes(
    generic ? [] : [SHIPMENT_STATES.EXCEPTION_SHIPMENT_NOT_ARRIVED],
    state
  )

export const isNotDelivered = (
  state: SHIPMENT_STATES | SHIPMENT_GENERIC_STATES,
  generic: boolean = true
) =>
  includes(
    generic
      ? [SHIPMENT_GENERIC_STATES.NOT_DELIVERED]
      : [SHIPMENT_STATES.SHIPMENT_NOT_DELIVERED],
    state
  )

export const isDelivered = (
  state: SHIPMENT_STATES | SHIPMENT_GENERIC_STATES,
  generic: boolean = true
) =>
  includes(
    generic
      ? [
          SHIPMENT_GENERIC_STATES.DELIVERED,
          SHIPMENT_GENERIC_STATES.DELIVERED_TO_NEIGHBOR
        ]
      : [
          SHIPMENT_STATES.SHIPMENT_DELIVERED,
          SHIPMENT_STATES.SHIPMENT_DELIVERED_TO_NEIGHBOR
        ],
    state
  )

export const isDeliveredToNeighbor = (
  state: SHIPMENT_STATES | SHIPMENT_GENERIC_STATES,
  generic: boolean = true
) =>
  state ===
  (generic
    ? SHIPMENT_GENERIC_STATES.DELIVERED_TO_NEIGHBOR
    : SHIPMENT_STATES.SHIPMENT_DELIVERED_TO_NEIGHBOR)

export const isFinalState = (state: SHIPMENT_STATES) =>
  includes(
    [
      SHIPMENT_STATES.SHIPMENT_NOT_DELIVERED,
      SHIPMENT_STATES.SHIPMENT_DELIVERED,
      SHIPMENT_STATES.SHIPMENT_DELIVERED_TO_NEIGHBOR,
      SHIPMENT_STATES.EXCEPTION_SHIPMENT_CANCELLED_BY_TRUNKRS,
      SHIPMENT_STATES.EXCEPTION_SHIPMENT_CANCELLED_BY_SENDER
    ],
    state
  )

export const isFailureState = (state: SHIPMENT_STATES) =>
  includes(
    [
      SHIPMENT_STATES.SHIPMENT_NOT_DELIVERED,
      SHIPMENT_STATES.EXCEPTION_SHIPMENT_CANCELLED_BY_TRUNKRS,
      SHIPMENT_STATES.EXCEPTION_SHIPMENT_CANCELLED_BY_SENDER
    ],
    state
  )

export const isGenericFailureState = (state: SHIPMENT_GENERIC_STATES) =>
  includes(
    [SHIPMENT_GENERIC_STATES.CANCELED, SHIPMENT_GENERIC_STATES.NOT_DELIVERED],
    state
  )

export const isExpected = (stateName: string) =>
  convertToGenericState(stateName as SHIPMENT_STATES) ===
  SHIPMENT_GENERIC_STATES.EXPECTED

export const isInWarehouse = (stateName: string) =>
  convertToGenericState(stateName as SHIPMENT_STATES) ===
  SHIPMENT_GENERIC_STATES.IN_WAREHOUSE

export const isOnTheWay = (stateName: string) =>
  convertToGenericState(stateName as SHIPMENT_STATES) ===
  SHIPMENT_GENERIC_STATES.ON_THE_WAY

export const isAlmostThere = (stateName: string) =>
  convertToGenericState(stateName as SHIPMENT_STATES) ===
  SHIPMENT_GENERIC_STATES.ALMOST_THERE
