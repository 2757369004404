import { SHIPMENT_GENERIC_STATES, SHIPMENT_STATES } from '../constants'

export const convertToGenericState = (state: SHIPMENT_STATES) => {
  const {
    EXPECTED,
    IN_WAREHOUSE,
    ON_THE_WAY,
    DELIVERED,
    DELIVERED_TO_NEIGHBOR,
    NOT_DELIVERED,
    CANCELED
  } = SHIPMENT_GENERIC_STATES
  const nextState = {
    DATA_RECEIVED: EXPECTED,
    DATA_PROCESSED: EXPECTED,
    SHIPMENT_SORTED: IN_WAREHOUSE,
    SHIPMENT_SORTED_AT_SUB_DEPOT: IN_WAREHOUSE,
    SHIPMENT_ACCEPTED_BY_DRIVER: ON_THE_WAY,
    SHIPMENT_DELIVERED: DELIVERED,
    SHIPMENT_DELIVERED_TO_NEIGHBOR: DELIVERED_TO_NEIGHBOR,
    SHIPMENT_NOT_DELIVERED: NOT_DELIVERED,
    PICKUP_DRIVER_ASSIGNED: EXPECTED,
    PICKUP_ACCEPTED_BY_DRIVER: EXPECTED,
    PICKUP_PICKED_UP: EXPECTED,
    PICKUP_ARRIVED_ON_TIME: EXPECTED,
    LINEHAUL_IN_TRANSIT: EXPECTED,
    LINEHAUL_ARRIVED_ON_TIME: EXPECTED,
    RETURN_ASSIGNED_TO_LINEHAUL: EXPECTED,
    RETURN_ACCEPTED_BY_DRIVER: EXPECTED,
    RETURN_ACCEPTED_BY_TRUNKRS: EXPECTED,
    RETURN_ASSIGNED_TO_PICKUP: EXPECTED,
    RETURN_ACCEPTED_BY_PICKUP_DRIVER: EXPECTED,
    RETURN_ACCEPTED_BY_SENDER: EXPECTED,
    CHANGE_SHIPMENT_POST_PONED: NOT_DELIVERED,
    CHANGE_SHIPMENT_ADDRESS_CHANGED: EXPECTED,
    CHANGE_SHIPMENT_NEIGHBOUR_CHANGE: EXPECTED,
    EXCEPTION_PICKUP_REFUSED_BY_DRIVER: NOT_DELIVERED,
    EXCEPTION_PICKUP_NOT_PICKED_UP: NOT_DELIVERED,
    EXCEPTION_PICKUP_ARRIVED_LATE: NOT_DELIVERED,
    EXCEPTION_SHIPMENT_NOT_ARRIVED: EXPECTED,
    EXCEPTION_LINEHAUL_NOT_ON_LINEHAUL: NOT_DELIVERED,
    EXCEPTION_SHIPMENT_MIS_SORTED: NOT_DELIVERED,
    EXCEPTION_SHIPMENT_DECLINED_BY_DRIVER: NOT_DELIVERED,
    EXCEPTION_SHIPMENT_MISSING: NOT_DELIVERED,
    EXCEPTION_SHIPMENT_LOST: NOT_DELIVERED,
    EXCEPTION_RETURN_NOT_ACCEPTED_BY_LINEHAUL: NOT_DELIVERED,
    EXCEPTION_RETURN_NOT_ACCEPTED_BY_PICKUP: NOT_DELIVERED,
    EXCEPTION_RETURN_NOT_ACCEPTED_BY_SENDER: NOT_DELIVERED,
    EXCEPTION_SHIPMENT_CANCELLED_BY_SENDER: CANCELED,
    EXCEPTION_SHIPMENT_CANCELLED_BY_TRUNKRS: CANCELED,
    EXCEPTION_SHIPMENT_DESTROYED: NOT_DELIVERED,
    EXCEPTION_LINEHAUL_ARRIVED_LATE: NOT_DELIVERED,
    RETURN_SHIPMENT_TO_SENDER: EXPECTED
  }[state]

  return nextState ? nextState : EXPECTED
}
