import * as React from 'react'
import clsx from 'clsx'
import defaultTo from 'lodash/defaultTo'

import './style.scss'

interface IModalProps {
  show?: boolean
  className?: string
  children?: React.ReactNode
}

export const Modal = ({ className, show, children }: IModalProps) => {
  return (
    <div
      className={clsx(
        'modal',
        { 'd-block': defaultTo(show, false) },
        defaultTo(className, '')
      )}
      tabIndex={-1}
      role="dialog"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">{children}</div>
      </div>
    </div>
  )
}
