import * as React from 'react'
import moment from 'moment'

export class TimeTicker extends React.Component<
  { initialTimestamp: string; id?: string; className?: string },
  {}
> {
  public interval: any = 0

  public tick() {
    this.setState({})
  }

  public componentDidMount() {
    this.interval = setInterval(() => this.tick(), 1000)
  }

  public componentWillUnmount() {
    clearInterval(this.interval)
  }

  public render() {
    const { initialTimestamp, ...rest } = this.props
    return initialTimestamp ? (
      <span {...rest}>{moment(initialTimestamp).fromNow()}</span>
    ) : null
  }
}
