import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import * as Sentry from '@sentry/browser'

import moment from 'moment'
import 'moment/locale/nl'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'

import { ENVIRONMENT, SENTRY_DSN } from './constants'
import { Routes } from './app/routing'
import { AppStore, ShipmentStore, AuthStore } from './app/store'

import * as ReactGA from 'react-ga'
ReactGA.initialize('UA-86398557-5') /* Initializing GA and Tracking */

moment.locale('nl')

// @ts-ignore
library.add(fas, far)

Sentry.init({
  dsn: SENTRY_DSN,
  environment: ENVIRONMENT
})

ReactDOM.render(
  <AppStore>
    <AuthStore>
      <ShipmentStore>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </ShipmentStore>
    </AuthStore>
  </AppStore>,
  document.getElementById('root') as HTMLElement
)
