import * as React from 'react'
import { useShipmentActions } from './actions'
import { IShipmentState, initialState } from './state'
// export const ErrorHandlerContext = React.createContext({} as IErrorHandlerState)
export const ShipmentContext = React.createContext({} as IShipmentState)

export default ({ children }: { children: React.ReactNode }) => {
  const state = useShipmentActions(initialState)

  ShipmentContext.displayName = 'Shipment'

  return (
    <ShipmentContext.Provider value={state}>
      {children}
    </ShipmentContext.Provider>
  )
}
