import * as React from 'react'

import * as Sentry from '@sentry/browser'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'

import {
  ITrackTraceInformation,
  TrackAndTalk
} from 'Trunkrs-SDK/dist/models/communications'
import {
  IAttempts,
  IDailyMessageResponse,
  IShipmentLog,
  IStates
} from 'Trunkrs-SDK/dist/models/communications/TrackAndTalk'

import API from '../../../api/v2'
import { SHIPMENT_STATES } from '../../../constants'
import { IIdentity, IShipmentHistory } from '../../../models'
import {
  isCanceled,
  isDelivered,
  isFinalState,
  isNotDelivered
} from '../../../utils'
import { unwrapResponse } from '../../hooks'

import { IShipmentState } from './state'

const trackAndTalk = API.TrackAndTalk()

export const useShipmentActions = (initialState: IShipmentState) => {
  const [sdkInstance, setSDKInstance] = React.useState<TrackAndTalk>()

  const [
    shipmentInfo,
    setShipmentInfo
  ] = React.useState<ITrackTraceInformation | null>(initialState.shipmentInfo)

  const [
    notification,
    setNotification
  ] = React.useState<IDailyMessageResponse | null>(initialState.notification)
  const [shipmentHistory, setShipmentHistory] = React.useState<
    IShipmentHistory[]
  >([])

  const fetchShipmentInfo = React.useCallback(
    async ({
      trunkrsNr,
      postalCode
    }: IIdentity): Promise<TrackAndTalk | null> => {
      const [, response] = await unwrapResponse(
        trackAndTalk.getShipmentInformation(trunkrsNr, postalCode)
      )
      if (!response) {
        return null
      }

      setShipmentInfo(response)
      Sentry.setTags({ 'trunkrs-nr': trunkrsNr })

      const [, responseNotification] = await unwrapResponse(
        trackAndTalk.getTodaysMessage(trunkrsNr, postalCode, new Date())
      )

      if (responseNotification) {
        setNotification(responseNotification)
      }

      const [, responseSDKInstance] = await unwrapResponse(
        trackAndTalk.setup(trunkrsNr, postalCode)
      )
      if (responseSDKInstance) {
        setSDKInstance(responseSDKInstance)
      }

      return responseSDKInstance
    },
    []
  )

  const fetchShipmentHistory = React.useCallback(
    async (
      { trunkrsNr, postalCode }: IIdentity,
      shipmentLogs: Array<IShipmentLog & { reasonCode?: { code: string } }>
    ) => {
      const [, response] = await unwrapResponse(
        trackAndTalk.getShipmentHistory(trunkrsNr, postalCode)
      )

      if (response && response.attempts) {
        const historySortByDate = sortBy(response.attempts, 'date').map(
          (attempt: IAttempts) => {
            let currentStateName = get(
              attempt,
              'states.0.name',
              SHIPMENT_STATES.DATA_RECEIVED
            )
            if (
              currentStateName === SHIPMENT_STATES.CHANGE_SHIPMENT_POST_PONED
            ) {
              currentStateName = get(
                attempt,
                'states.1.name',
                SHIPMENT_STATES.DATA_RECEIVED
              )
            }

            const enrichedStates = attempt.states.map(state => {
              if (state.name !== SHIPMENT_STATES.SHIPMENT_NOT_DELIVERED) {
                return state
              }

              const shipmentLog = shipmentLogs.find(
                log =>
                  log.state === state.name && log.timestamp === state.timestamp
              )
              return {
                ...state,
                reasonCode: get(shipmentLog, 'reasonCode.code')
              } as IStates
            })

            return {
              ...attempt,
              states: enrichedStates,
              currentState: currentStateName,
              isCanceled: isCanceled(currentStateName),
              isNotDelivered: isNotDelivered(currentStateName),
              isDelivered: isDelivered(currentStateName),
              isFinalState: isFinalState(currentStateName)
            } as IShipmentHistory
          }
        )
        setShipmentHistory(historySortByDate)
      }
    },
    []
  )

  const fetchAllShipmentInfo = React.useCallback(
    async (identity: IIdentity) => {
      const trackTalkInstance = (await fetchShipmentInfo(
        identity
      )) as TrackAndTalk
      const shipmentLogs = trackTalkInstance.getShipmentLogs

      await fetchShipmentHistory(identity, shipmentLogs)
    },
    [fetchShipmentHistory, fetchShipmentInfo]
  )

  const subscribeToSMS = React.useCallback(
    async (phoneNumber: string) => {
      if (sdkInstance) {
        const [err] = await unwrapResponse(
          sdkInstance.subscribeToSMS(phoneNumber)
        )

        if (!err) {
          return true
        }
      }
      return false
    },
    [sdkInstance]
  )

  return React.useMemo(
    () => ({
      shipmentInfo,
      notification,
      shipmentHistory,
      fetchShipmentInfo,
      fetchShipmentHistory,
      fetchAllShipmentInfo,
      subscribeToSMS
    }),
    [
      shipmentInfo,
      notification,
      shipmentHistory,
      fetchShipmentInfo,
      fetchShipmentHistory,
      fetchAllShipmentInfo,
      subscribeToSMS
    ]
  )
}
