export const callbackTimer = (callback: any, duration: number) => {
  const f = (d: number, t1: number = 0) => (t2: number) => {
    if (t1 === 0) {
      t1 = t2
    }
    t2 - t1 > d ? callback() : window.requestAnimationFrame(f(d, t1))
  }

  window.requestAnimationFrame(f(duration))
}
