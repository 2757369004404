import * as React from 'react'
import './polyspinner.css'

export const PolySpinner = () => {
  return (
    <div className="poly-loader">
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <span />
      <span />
      <span />
    </div>
  )
}
