import * as React from 'react'
import clsx from 'clsx'

import { objectEntries, callbackTimer } from '../../../../utils'

import './popupbox.css'

export interface IPopupBoxProps {
  modal?: boolean
  visible?: boolean
  handleSubmit?: any
  handleCancel?: any
  handleClose?: any
  controls?: any
}

export const initialState: IPopupBoxProps & { destroy?: boolean } = {
  modal: true,
  visible: true,
  destroy: false
}

export type IPopupBoxState = Readonly<typeof initialState>

const getNextStateFromProps = (
  nextProps: IPopupBoxProps,
  prevState: IPopupBoxState
) => {
  let nextState = {}

  objectEntries(nextProps).forEach(([key, value]) => {
    if (value !== prevState[key] && prevState.hasOwnProperty(key)) {
      nextState = {
        [key]: value
      }
    }
  })

  return Object.keys(nextState).length === 0 ? null : nextState
}

export class PopupBox extends React.Component<IPopupBoxProps, IPopupBoxState> {
  public static getDerivedStateFromProps(
    props: IPopupBoxProps,
    state: IPopupBoxState
  ) {
    const derivedState = getNextStateFromProps(props, state)

    return derivedState ? { ...derivedState, destroy: false } : null
  }

  constructor(props: any) {
    super(props)
    const derivedState = getNextStateFromProps(props, initialState)
    this.state = { ...initialState, ...derivedState }
  }

  public handleClose = () => {
    if (this.props.hasOwnProperty('handleClose')) {
      this.setState({
        visible: false
      })

      callbackTimer(() => {
        this.setState({
          destroy: true
        })

        this.props.handleClose()
      }, 1000)
    }
  }

  public handleSubmit = () => {
    if (this.props.hasOwnProperty('handleSubmit')) {
      this.setState({
        visible: false
      })

      callbackTimer(() => {
        this.setState({
          destroy: true
        })
        this.props.handleSubmit()
      }, 1000)
    }
  }

  public handleCancel = () => {
    if (this.props.hasOwnProperty('handleCancel')) {
      this.setState({
        visible: false
      })

      callbackTimer(() => {
        this.setState({
          destroy: true
        })

        this.props.handleCancel()
      }, 1000)
    }
  }

  public render() {
    const { modal, visible, destroy } = this.state

    const hasOwnControls = !!this.props.controls
    const hasSubmit = !!this.props.handleSubmit && !hasOwnControls
    const hasCancel = !!this.props.handleCancel && !hasOwnControls

    if (destroy) {
      return null
    }

    return (
      <div className={clsx('popup', { modal, show: visible })}>
        <div className="popup-content">
          <span className="close-button" onClick={this.handleClose} />
          <div className="popup-body">{this.props.children}</div>
          <div className="popup-controls">
            {hasOwnControls && this.props.controls}
            {hasSubmit && (
              <button
                className="flat-btn control-button"
                onClick={this.handleSubmit}
              >
                Yes
              </button>
            )}
            {hasCancel && (
              <button
                className="flat-btn control-button"
                onClick={this.handleCancel}
              >
                No
              </button>
            )}
          </div>
        </div>
      </div>
    )
  }
}
