class Log {
  // tslint:disable
  _lines: Line[] = []
  // tslint:enable

  get lines() {
    return this._lines
  }

  set line(line: Line) {
    this._lines.push(line)
  }
}

class Line {
  // tslint:disable
  // @ts-ignore
  _type: string
  // @ts-ignore
  _content: string
  // @ts-ignore
  _timestamp: Date
  // tslint:enable

  constructor(type: string, content: string) {
    this.type = type
    this.content = content
  }

  get type(): string {
    return this._type
  }

  set type(type: string) {
    this._type = type
  }

  get content(): string {
    return this._content
  }

  set content(content: string) {
    this._content = content
  }

  get timestamp(): Date {
    return this._timestamp
  }

  set timestamp(timestamp: Date) {
    this._timestamp = timestamp
  }
}

export const ledger: Log = new Log()

export function log(type: string, content: string): void {
  ledger.line = new Line(type, content)
  if (type.search('ERROR') !== -1) {
    // tslint:disable-next-line
    console.error([...ledger.lines].pop())
  }
}
