import * as React from 'react'
import moment from 'moment'
import clsx from 'clsx'

import { SHIPMENT_GENERIC_STATES } from '../../../../../../constants'
import {
  convertToGenericState,
  isCanceled,
  isCanceledSender,
  isCanceledTrunkrs,
  isDelivered,
  isDeliveredToNeighbor,
  isNotDelivered
} from '../../../../../../utils'

import { ShipmentContext } from '../../../../../store/shipment'

import { Accordion, ModalBox, Translate } from '../../../../../base/components'
import { TimelineProgress } from './StatusTimeline'

export const ShippingHistory = (props: { handleClose: () => void }) => {
  const { handleClose } = props
  const { shipmentHistory } = React.useContext(ShipmentContext)

  return (
    <div id="shipping-history" className="d-none d-md-block d-lg-block">
      <ModalBox
        show={true}
        handleClose={handleClose}
        title={Translate.translate('SHIPPING_HISTORY')}
      >
        <Accordion
          items={shipmentHistory.map((history, historyIndex) => {
            const { date, currentState } = history
            const dateLabel = moment(date).calendar(undefined, {
              sameDay: `[${Translate.translate('TODAY')}]`,
              nextDay: `[${Translate.translate('TOMORROW')}]`,
              nextWeek: 'dddd',
              lastDay: `[${Translate.translate('YESTERDAY')}]`,
              lastWeek: `[${Translate.translate('LAST')}] dddd`,
              sameElse: 'DD-MM-YYYY'
            })

            const currentGenericState = convertToGenericState(currentState)

            return {
              header: (
                <div className="header">
                  <div className="row">
                    <div className="col-2 number">{`#${historyIndex + 1}`}</div>
                    <div className="col-3 date">{dateLabel}</div>
                    <div
                      className={clsx('col-7 status', {
                        'not-delivered': isNotDelivered(currentGenericState),
                        canceled: isCanceled(currentGenericState),
                        delivered: isDelivered(currentGenericState)
                      })}
                    >
                      {getHeader(currentGenericState)}
                    </div>
                  </div>
                </div>
              ),
              content: <TimelineProgress shipmentHistory={history} />
            }
          })}
        />
        <div className="modal-actions">
          <button
            className="flat-btn green control-button"
            onClick={handleClose}
          >
            <Translate>CLOSE</Translate>
          </button>
        </div>
      </ModalBox>
    </div>
  )
}

const getHeader = (state: SHIPMENT_GENERIC_STATES) => {
  switch (true) {
    case isDeliveredToNeighbor(state):
      return <Translate>AT_YOUR_NEIGHBOURS</Translate>
    case isDelivered(state):
      return <Translate>DELIVERED</Translate>
    case isNotDelivered(state):
      return <Translate>NOT_DELIVERED</Translate>
    case isCanceledTrunkrs(state):
      return <Translate>CANCELED_DELIVERY_TRUNKRS</Translate>
    case isCanceledSender(state):
      return <Translate>CANCELED_DELIVERY_SENDER</Translate>
    default:
      return <Translate>EXPECTED_DELIVERY</Translate>
  }
}
