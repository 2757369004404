import moment from 'moment'
import isEmpty from 'lodash/isEmpty'

export const isToday = (d?: Date | string): boolean => {
  if (isEmpty(d)) {
    return false
  }
  const today = moment().startOf('day')
  return moment(d).isSame(today, 'd')
}
