export const ON_BOARDING_FLOW = [
  {
    flow: 'waving-hand',
    text_1: 'ONBOARDING_GREETING_1',
    text_2: 'ONBOARDING_GREETING_2'
  },
  {
    flow: 'status',
    text_1: 'ONBOARDING_STATUS_1',
    text_2: 'ONBOARDING_STATUS_2'
  },
  {
    flow: 'time',
    text_1: 'ONBOARDING_TIME_1',
    text_2: 'ONBOARDING_TIME_2'
  },
  {
    flow: 'chat',
    text_1: 'ONBOARDING_CHAT_1',
    text_2: 'ONBOARDING_CHAT_2'
  },
  {
    flow: 'sms',
    text_1: 'ONBOARDING_SMS_1',
    text_2: 'ONBOARDING_SMS_2'
  }
]

export const TRUNKRS_ON_BOARDING = 'TRUNKRS_ON_BOARDING'
