export enum REASON_CODES {
  NO_DELIVERY_DEFAULT,
  NO_DELIVERY_RECIPIENT_NOT_HOME,
  NO_DELIVERY_MISSORT,
  NO_DELIVERY_BUSINESS_CLOSED,
  NO_DELIVERY_NO_TIME_IN_TIMESLOT,
  NO_DELIVERY_EXTREME_WEATHER,
  NO_DELIVERY_DRIVER_ACCIDENT,
  NO_DELIVERY_REFUSED_BY_CUSTOMER
}
