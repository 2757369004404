import * as React from 'react'
import { ChatBotContext } from '../../../../../../store/chatbot'

import { Chat } from '../Chat'
import { AddressNeighbourChange } from '../AddressNeighbourChange'

import './styles.scss'

export const MessageFeed = () => {
  const {
    messageHistory,
    forAddressChange,
    forNeighbourUpdate
  } = React.useContext(ChatBotContext)
  const messageEndRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollTop = messageEndRef.current.scrollHeight
    }
  }, [])

  return (
    <div className="message-feed">
      {messageHistory.map((messageInstance, index) => (
        <Chat key={index} instance={messageInstance} />
      ))}
      {(forAddressChange || forNeighbourUpdate) && <AddressNeighbourChange />}
      <div ref={messageEndRef} />
    </div>
  )
}
