import * as React from 'react'
import clsx from 'clsx'

import { isValidPhoneNumber } from '../../../../../utils'
import { ICountryPhoneCode } from '../../../../../models'
import { COUNTRY_PHONE_CODES } from '../../../../../constants/COUNTRY_PHONE_CODE'

import { AppContext } from '../../../../store/app'
import { ShipmentContext } from '../../../../store/shipment'

import { Translate } from '../../../../base/components'

import 'flag-icon-css/css/flag-icon.min.css'
import './styles.scss'

export const SmsSubscription = ({ callback }: { callback: () => void }) => {
  const { setLoading } = React.useContext(AppContext)
  const { subscribeToSMS } = React.useContext(ShipmentContext)
  const [sms, setSMS] = React.useState<{
    alphaCode: string
    prefix: string
    phoneNumber?: string
  }>({ alphaCode: 'nl', prefix: '+31' })
  const [isPristine, setIsPristine] = React.useState(true)
  const [hasActiveNumber, setHasActiveNumber] = React.useState(false)
  const [hasErrors, setHasErrors] = React.useState()
  const [flag, setFlag] = React.useState(false)
  const toggleDropdown = () => setFlag(!flag)

  const { alphaCode, phoneNumber, prefix } = sms

  const handlePhoneNumberChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    if (isPristine) {
      setIsPristine(false)
    }
    setHasErrors(false)
    setSMS({ ...sms, phoneNumber: ev.currentTarget.value })
  }

  const handleCountryCodeChange = (value: ICountryPhoneCode) => () => {
    if (isPristine) {
      setIsPristine(false)
    }
    setHasErrors(false)
    setSMS({ ...sms, alphaCode: value.alphaCode, prefix: value.prefix })
    setFlag(false)
  }

  const subscribePhoneNumber = async () => {
    const phoneNumberWithPrefix = prefix + phoneNumber
    const isValid = isValidPhoneNumber(alphaCode, phoneNumberWithPrefix)
    if (isValid && phoneNumber) {
      setLoading(true)
      const result = await subscribeToSMS(phoneNumberWithPrefix)
      if (result) {
        setHasActiveNumber(true)
        if (callback) {
          callback()
        }
      } else {
        setHasErrors(true)
      }
      setLoading(false)
    } else {
      setHasErrors(true)
    }
  }

  return (
    <div id="sms-subscription-form-container">
      <div className="text-message-form">
        <span className="text-message-form-detail">
          <Translate>
            {!hasActiveNumber ? 'TEXT_MESSAGE_REG' : 'TEXT_MESSAGE_UPDATE'}
          </Translate>
        </span>
      </div>
      <div
        className={clsx('text-message-input-field', {
          'has-errors': hasErrors
        })}
      >
        <div id="sms-country-code-selector">
          <div className="drop-down-list">
            <div className="drop-down" onClick={toggleDropdown}>
              <span className="text">{alphaCode}</span>
              <span
                className={`flag-icon-background flag-icon-${alphaCode} flag-box`}
              />
              <span
                className={`${flag ? 'expand-less-icon' : 'expand-more-icon'}`}
              />
            </div>
            <div
              className={clsx(
                'drop-down-content',
                flag ? 'expand-more' : 'expand-less'
              )}
            >
              <ul>
                {COUNTRY_PHONE_CODES.map((value, index) => (
                  <li
                    className={clsx('drop-down', {
                      selected: alphaCode === value.alphaCode
                    })}
                    key={index}
                    onClick={handleCountryCodeChange(value)}
                  >
                    <span className="text">{value.country}</span>
                    <span
                      className={`flag-icon-background flag-icon-${value.alphaCode} flag-box`}
                    />
                    <span className="drop-down-list-end" />
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="input-field">
            <span className="phone-code-prefix">({prefix})</span>
            <input
              onChange={handlePhoneNumberChange}
              value={phoneNumber}
              className="input"
              type="text"
            />
          </div>
        </div>
      </div>
      <div className="text-message-save-number">
        <div
          className={clsx('flat-btn-save-number', {
            disabled: isPristine || hasErrors,
            green: !isPristine && !hasErrors
          })}
          onClick={subscribePhoneNumber}
        >
          <Translate>SAVE</Translate>
        </div>
      </div>
    </div>
  )
}
// const getCountryPhoneCode = (alphaCode: string) => COUNTRY_PHONE_CODES.find(phoneCode => phoneCode.alphaCode === alphaCode);
