import * as React from 'react'

export const eventHandler = (
  func: (args: any) => void,
  definedValue: any = undefined
) => {
  return (ev?: React.SyntheticEvent<any>) => {
    if (definedValue !== undefined) {
      func(definedValue)
    } else {
      if (ev && ev.hasOwnProperty('currentTarget')) {
        func(ev.currentTarget.value)
      }
    }
  }
}
