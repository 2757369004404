import * as React from 'react'
import * as Sentry from '@sentry/browser'

import { ChatBotContext } from '../../../../store/chatbot'
import { AppContext } from '../../../../store/app'
import { AuthContext } from '../../../../store/auth'

import { MessageFeed, MessageInputs } from './components'

import './styles.scss'

export const ChatBot: React.FC = ({ children }) => {
  const { setLoading } = React.useContext(AppContext)
  const { identity } = React.useContext(AuthContext)
  const {
    initChatBot,
    forAddressChange,
    forNeighbourUpdate
  } = React.useContext(ChatBotContext)

  React.useEffect(() => {
    if (identity) {
      setLoading(true)
      initChatBot(identity)
        .then(() => setLoading(false))
        .catch(error => {
          Sentry.captureException(error)
          setLoading(false)
        })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div id="chat-box" className="chat-box">
      <div className="message-container">
        <div className="message-top">
          <div className="side">{children}</div>
          <div className="title">CHAT</div>
          <div className="side" />
        </div>
        <div className="message-feed-wrapper">
          <MessageFeed />
          {!forAddressChange && !forNeighbourUpdate && <MessageInputs />}
        </div>
      </div>
    </div>
  )
}
