export default {
  APP_NAME: 'Trunkrs',
  WELCOME: 'herzlich willkommen',
  LOGIN: 'Anmeldung',
  USERNAME: 'Benutzername',
  EMAIL: 'E-Mail-Addresse',
  PASSWORD: 'Passwort',
  RESTORE_PASSWORD: 'Passwort vergessen?',
  SEND_EMAIL: 'Passwort zurücksetzen',
  SEND_EMAIL_MSG:
    'Füllen Sie E-Mail-Adresse aus und wir senden Ihnen ein neues Passwort zu.',
  EMAIL_SENT_MSG:
    'Wir haben ein neues Passwort an Ihre E-Mail Adresse gesendet. Sobald Sie sich mit Ihrem neuen Passwort angemeldet haben, können Sie Ihr Passwort auf Ihrer Profilseite ändern.',
  BACK: 'Zurück',
  LOGOUT: 'Ausloggen',
  ADDRES: 'Adresse',
  HOUSE_NUMBER: 'Hausnummer',
  POSTAL_CODE: 'Postleitzahl',
  SEARCH_TEXT: 'Willkommen bei',
  SEARCH_DESC: 'Alles über Ihr Paket, durch Ihr Paket',
  TRUNKRS_NR: 'Trunkrs Nummer',
  CANT_FIND: 'Hilfe, ich kann meine Sendung nicht finden',
  CONTACT_US:
    'Bitte kontaktieren Sie uns mit dem Live-IChat in der unteren rechten Ecke',
  SEARCH: 'suchen',
  SEARCH_ERR:
    'Wir können Ihre Sendung nicht finden die eingegebenen Daten. Bitte ändern Sie Ihre Eingabe und versuchen Sie es erneut.',
  CITY: 'Stadt',
  COUNTRY: 'Land',
  STATE: 'Status',
  TOUR: 'Route',
  DRIVER: 'Treiber',
  THE_NETHERLANDS: 'Die Niederlande',
  GERMANY: 'Deutschland',
  TYPE_MSG: 'Fragen Sie hier Ihre Frage...',
  ETA: 'Ankunftszeit',
  SETTING_NOTIFICATIONS: 'Desktop-Benachrichtigungen',
  SETTING_PRIORITY: 'Wichtige Meldungen nur',
  LOADING: 'einen Moment bitte',
  LOADING_MSG1: 'Umzug Berge nur für Sie!',
  LOADING_MSG2: 'Sechs Bananen + neun Tropfen = Ihr Paket!',
  LOADING_MSG3:
    'Blinzeln Sie dreimal und Ihr Paket wird gefunden, probieren Sie es aus!',
  LOADING_MSG4: 'A B C D E F G H I J K L M N O Paket',
  LOADING_MSG5: 'Satelliten werden positioniert, um Ihr Paket zu lokalisieren',
  LOADING_MSG6: 'Satelliten werden positioniert, um Ihr Paket zu lokalisieren',
  LOADING_MSG7: 'Plata o Paket?!',
  LOADING_MSG8: 'Uw zending wordt opgehaald.',
  LOADING_MSG9: 'Uw zending wordt opgehaald.',
  LOADING_MSG10: 'Uw zending wordt opgehaald.',
  FEEDBACK_PLACEHOLDER: 'Warum?',
  PUSH_ENABLED_TITLE: 'Subscribed',
  PUSH_ENABLED_BODY: 'Your subscribed for push notifications',
  STATE_1: 'Im lagerhaus',
  STATE_2: 'Im lagerhaus',
  STATE_3: 'Im lagerhaus',
  STATE_4: 'Im lagerhaus',
  STATE_5: 'auf dem Weg',
  STATE_6: 'auf dem Weg',
  STATE_7: 'Almost there!',
  STATE_8: 'Im lagerhaus',
  STATE_9: 'Im lagerhaus',
  STATE_10: 'Geliefert',
  STATE_11: 'Geliefert an Nachbar',
  STATE_12: 'Geliefert fehlgeschlagen :(',
  STATE_13: 'auf dem Weg',
  STATE_14: 'auf dem Weg',
  STATE_15: 'auf dem Weg',
  STATE_16: 'auf dem Weg',
  STATE_17: 'Der Versand wurde storniert',
  STATE_18: 'auf dem Weg',
  PARCEL_INFORMATION: 'Parcel information'
}
