import * as React from 'react'
import get from 'lodash/get'
import { StaticContext } from 'react-router'
import { Redirect, Route, Switch, RouteComponentProps } from 'react-router-dom'

import API from '../../api/v2'
import { INIT_URL } from '../../constants'
import { RouterContext } from '../store/context'
import routes from './routes'

export interface IRoute {
  exact?: boolean
  path: string
  component: any
  public?: boolean
  [key: string]: any
}

const RedirectToInitUrl = () => <Redirect to={{ pathname: INIT_URL }} />

const Component = (props: IRoute) => {
  const { exact, path, component: WrappedComponent } = props
  const render = (routeProps: RouteComponentProps<any, StaticContext, any>) => (
    <WithRouterContext {...routeProps}>
      <WrappedComponent />
    </WithRouterContext>
  )

  const matcher = (routeProps: RouteComponentProps) => {
    if (routeProps.match.path === '/:trunkrsNr/:postalCode') {
      if (get(routeProps, 'match.params.postalCode', '').length > 6) {
        const trackAndTalk = API.TrackAndTalk()
        trackAndTalk
          .getPostalCode(
            // in this case its /token/trunkrsNr, so postalCode = trunkrsNR and trunkrsNr = token
            get(routeProps, 'match.params.postalCode', ''),
            get(routeProps, 'match.params.trunkrsNr', '')
          )
          .then(({ postalCode }) =>
            routeProps.history.push(
              `/${get(
                routeProps,
                'match.params.postalCode',
                ''
              )}/${postalCode.toUpperCase()}`
            )
          )
          .catch(() => routeProps.history.push('/'))
        return
      } else {
        return render(routeProps)
      }
    } else {
      return render(routeProps)
    }
  }

  return <Route {...{ exact, path }} render={matcher} />
}

const WithRouterContext = (
  props: RouteComponentProps<any> & { children: React.ReactNode }
) => {
  const { children, ...rest } = props
  return (
    <RouterContext.Provider value={rest}>{children}</RouterContext.Provider>
  )
}

export const Routes = () => (
  <Switch>
    {React.useMemo(
      () => routes.map(route => <Component key={route.path} {...route} />),
      []
    )}

    <Route component={RedirectToInitUrl} />
  </Switch>
)
