import * as React from 'react'
import { useChatBotActions } from './actions'
import { IChatBotState, initialState } from './state'
export const ChatBotContext = React.createContext({} as IChatBotState)

export default ({ children }: { children: React.ReactNode }) => {
  const state = useChatBotActions(initialState)

  ChatBotContext.displayName = 'ChatBot'

  return (
    <ChatBotContext.Provider value={state}>{children}</ChatBotContext.Provider>
  )
}
