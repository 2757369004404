import React, { memo, useCallback } from 'react'
import get from 'lodash/get'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  GOOGLE_REVIEW_LINK,
  TRUSTPILOT_LINK,
  TYPEFORM_LINK,
  VOTE_RATING
} from '../../../../../../constants'
import { openNewTab } from '../../../../../../utils'
import { ShipmentContext } from '../../../../../store/shipment'
import { Translate } from '../../../../../base/components/translate'
import { usePersistedState } from '../../../../../hooks'

const ServiceReview = () => {
  const { shipmentInfo } = React.useContext(ShipmentContext)

  const trunkrsNr = get(shipmentInfo, 'parcel.trunkrsNr', '')

  const [isVoted, setIsVoted] = usePersistedState<boolean>(
    `___TRACK_AND_TALK_VOTE_FLAG_${trunkrsNr}`,
    false
  )
  const [currentVote, setCurrentVote] = usePersistedState<VOTE_RATING>(
    `___TRACK_AND_TALK_SELECTED_VOTE_${trunkrsNr}`
  )

  const setVote = useCallback(
    (vote: VOTE_RATING) => {
      setCurrentVote(vote)
      setIsVoted(true)

      if (vote === VOTE_RATING.GREAT) {
        openNewTab(Math.random() <= 0.3 ? GOOGLE_REVIEW_LINK : TRUSTPILOT_LINK)
      } else {
        openNewTab(`${TYPEFORM_LINK}${trunkrsNr}`)
      }
    },
    [currentVote] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const handlePositiveVote = useCallback(() => setVote(VOTE_RATING.GREAT), []) // eslint-disable-line react-hooks/exhaustive-deps
  const handleNegativeVote = useCallback(
    () => setVote(VOTE_RATING.COULD_BE_BETTER),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const t = Translate.translate

  return (
    <div id="service-review">
      {!isVoted ? (
        <div>
          <p className="heading">{t('HELP_US_IMPROVE')}</p>
          <p className="sub-heading">
            {t('HOW_DID_YOU_EXPERIENCE_OUR_SERVICE')}
          </p>
          <div className="buttons-container">
            <div className="icon-buttons positive" onClick={handlePositiveVote}>
              <FontAwesomeIcon icon={['far', 'grin']} />
              <span className="text hidden-md">{t('IT_WAS_GREAT')}</span>
            </div>
            <div className="icon-buttons negative" onClick={handleNegativeVote}>
              <FontAwesomeIcon icon={['far', 'grin-beam-sweat']} />
              <span className="text hidden-md">{t('COULD_BE_BETTER')}</span>
            </div>
          </div>
        </div>
      ) : (
        <div className="feedback-container">
          <p className="heading">{t('THANK_YOU_FOR_HELPING')}</p>
          <p className="sub-heading">{t('WE_HAVE_SAVED_YOUR_VOTE')}</p>
        </div>
      )}
    </div>
  )
}

export default memo(ServiceReview)
