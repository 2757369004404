import moment from "moment-timezone";

export enum TIMEZONES {
  NL = "Europe/Amsterdam",
  DE = "Europe/Berlin"
}

export const convertTimezone = (date: Date|string, format: string = "YYYY-MM-DD HH:mm" , timeZone = TIMEZONES.NL) => moment(date).tz(timeZone).format(format);

// const formatDate = (date: Date|string) => moment(date).utc().format("YYYY-MM-DD HH:mm")
