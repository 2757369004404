import * as React from 'react'
import * as Sentry from '@sentry/browser'
import * as ReactGA from 'react-ga'

import { INIT_URL } from '../../../constants'
import { eventHandler } from '../../../utils'

import { ChatBotStore } from '../../store'
import {
  AuthContext,
  AppContext,
  ShipmentContext,
  RouterContext
} from '../../store/context'

import { Loading, ModalBox, ShareLink, Translate } from '../../base/components'
import { DeliveryInformation, ChatBot, SmsSubscription } from './components'

import './home.scss'
import './home.mobile.scss'

export const Home = () => {
  const { authenticate, authenticated, identity } = React.useContext(
    AuthContext
  )
  const { loading, setLoading } = React.useContext(AppContext)
  const { match, history } = React.useContext(RouterContext)
  const { shipmentInfo, fetchAllShipmentInfo } = React.useContext(
    ShipmentContext
  )
  const [expandHeader, setExpandHeader] = React.useState(false)
  const [chatBoxMobileIsVisible, setChatBoxMobileIsVisible] = React.useState(
    false
  )
  const [
    smsSubscriptionModalVisible,
    setSMSSubscriptionModalVisible
  ] = React.useState(false)
  const chatBoxContainerRef = React.useRef<HTMLDivElement>(null)
  const deliveryInfoContainerRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    const {
      params: { trunkrsNr, postalCode }
    } = match

    if (authenticated && identity) {
      setLoading(true)
      fetchAllShipmentInfo({ trunkrsNr, postalCode })
        .then(() => setLoading(false))
        .catch(error => {
          Sentry.captureException(error)
          setLoading(false)
        })
    } else {
      setLoading(true)
      authenticate(trunkrsNr, postalCode)
        .then(result => {
          if (!result) {
            history.push(INIT_URL)
          }
        })
        .then(() => {
          setLoading(false)
          return fetchAllShipmentInfo({ trunkrsNr, postalCode })
        })
        .catch(error => {
          Sentry.captureException(error)
          setLoading(false)
        })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const toggleHeader = () => {
    setExpandHeader(!expandHeader)

    /* Google analytics purposes only */
    if (expandHeader) {
      ReactGA.event({
        category: 'Button',
        action: 'Menu opened',
        label: 'Open menu button',
        nonInteraction: true
      })
    } else {
      ReactGA.event({
        category: 'Button',
        action: 'Menu closed',
        label: 'Close menu button',
        nonInteraction: true
      })
    }
  }

  const toggleMobileDisplay = () => {
    const { current: chatbox } = chatBoxContainerRef
    const { current: deliveryInfo } = deliveryInfoContainerRef

    if (chatbox && deliveryInfo) {
      const nextFlag = !chatBoxMobileIsVisible
      if (nextFlag) {
        chatbox.scrollIntoView({ behavior: 'smooth' })
      } else {
        deliveryInfo.scrollIntoView({ behavior: 'smooth' })
      }
      setChatBoxMobileIsVisible(!chatBoxMobileIsVisible)
    }
  }

  return (
    <div className="main-container">
      {shipmentInfo && (
        <div className="wrapper container-fluid">
          <div className="header-nav row no-gutters">
            <div className="col-5 align-items-center h-100">
              <div className="d-flex col-md-4 align-items-center h-100">
                <span className="trunkrs-logo-inline" />
              </div>
            </div>
            <div className="col-7">
              <div className="d-flex justify-content-end align-items-center h-100">
                <div className="trunkrs-redirect d-none d-md-inline-flex d-lg-inline-flex">
                  <ReactGA.OutboundLink
                    action="Visit website"
                    eventLabel="Visit website link"
                    to="https://www.trunkrs.nl"
                    target="_blank"
                  >
                    <Translate>VISIT_WEBSITE</Translate>
                  </ReactGA.OutboundLink>
                </div>
                {identity && (
                  <ShareLink
                    link={`https://parcel.trunkrs.nl/${identity.trunkrsNr}/${identity.postalCode}`}
                  />
                )}
                <div className="sign-out-link d-none d-md-inline-flex d-lg-inline-flex">
                  <ReactGA.OutboundLink
                    action="Log off"
                    eventLabel="Log off link"
                    to={INIT_URL}
                  >
                    <Translate>SIGN_OUT</Translate>
                  </ReactGA.OutboundLink>
                </div>
                <div className="d-inline-flex d-md-none d-lg-none">
                  <div className="btn-arrow" onClick={toggleHeader}>
                    <i className={`btn-${expandHeader ? 'up' : 'down'}`} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="content-body row no-gutters">
            <div
              id="delivery-info-container"
              ref={deliveryInfoContainerRef}
              className="col-9"
            >
              <DeliveryInformation headerExpanded={expandHeader} />
              <div
                className="sms circle-btn"
                onClick={eventHandler(setSMSSubscriptionModalVisible, true)}
              >
                SMS
              </div>
              <div className="chat-btn d-flex d-md-none d-lg-none">
                {!chatBoxMobileIsVisible && (
                  <div className="clickable" onClick={toggleMobileDisplay}>
                    <div className="chat-button">
                      <div className="text new-message">chat</div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div
              id="messages-container"
              ref={chatBoxContainerRef}
              className="col-3"
            >
              <ChatBotStore>
                <ChatBot>
                  <div className="back-arrow" onClick={toggleMobileDisplay} />
                </ChatBot>
              </ChatBotStore>
            </div>
          </div>
        </div>
      )}
      {smsSubscriptionModalVisible && (
        <ModalBox
          title={Translate.translate('TEXT_MESSAGE')}
          show={true}
          handleClose={eventHandler(setSMSSubscriptionModalVisible, false)}
        >
          <SmsSubscription
            callback={eventHandler(setSMSSubscriptionModalVisible, false)}
          />
        </ModalBox>
      )}
      <Loading loading={loading} />
    </div>
  )
}
