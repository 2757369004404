import * as React from 'react'
import { useAppActions } from './actions'
import { IAppState, initialState } from './state'
// export const ErrorHandlerContext = React.createContext({} as IErrorHandlerState)

export const AppContext = React.createContext({} as IAppState)
export default ({ children }: { children: React.ReactNode }) => {
  const state = useAppActions(initialState)

  AppContext.displayName = 'App'

  return <AppContext.Provider value={state}>{children}</AppContext.Provider>
}
