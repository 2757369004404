import { IIdentity } from '../../../models'

export interface IAuthState {
  identity: IIdentity | null
  authenticated: boolean
  authenticate: (trunkrsNr: string, postalCode: string) => Promise<boolean>
  getPostalCode: (trunkrsNr: string, token: string) => Promise<any>
  getTrunkrsNrViaBarcode: (barcode: string) => Promise<any>
}

export const initialState: IAuthState = {
  identity: null,
  authenticated: false,
  authenticate: () => Promise.resolve(false),
  getPostalCode: () => Promise.resolve(),
  getTrunkrsNrViaBarcode: () => Promise.resolve()
}
