export * from './CHAT_SPECIAL_ACTIONS'
export * from './CHAT_USER'
export * from './LANGUAGES'
export * from './ROUTES'
export * from './SHIPMENT_STATE'
export * from './DAYS'
export * from './ON_BOARDING_FLOW'
export * from './REASON_CODES'
export * from './NOTIFICATION_TYPE'

export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN
export const ENVIRONMENT = process.env.REACT_APP_ENV

export const GOOGLE_REVIEW_LINK = process.env
  .REACT_APP_GOOGLE_REVIEW_LINK as string
export const TRUSTPILOT_LINK = process.env.REACT_APP_TRUSTPILOT_LINK as string
export const TYPEFORM_LINK = process.env.REACT_APP_TYPEFORM_LINK as string

export enum VOTE_RATING {
  GREAT,
  COULD_BE_BETTER
}
