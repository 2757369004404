import { ICountryPhoneCode } from '../models'

export const COUNTRY_PHONE_CODES: ICountryPhoneCode[] = [
  { country: 'BE', alphaCode: 'be', prefix: '+32' },
  { country: 'DE', alphaCode: 'de', prefix: '+49' },
  { country: 'GB', alphaCode: 'gb', prefix: '+44' },
  { country: 'NL', alphaCode: 'nl', prefix: '+31' }
  // { country: 'US', alphaCode: 'us', phone: '+1' }
]

export const phoneRegExp = {
  nl: /^((\+|00(\s|\s?-\s?)?)31(\s|\s?-\s?)?(\(0\)[-\s]?)?|0)[1-9]((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]$/,
  be: /^((\+|00(\s|\s?-\s?)?)32(\s|\s?-\s?)?(\(0\)[-\s]?)?|0)[1-9]((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]$/,
  de: /^((\+|00(\s|\s?-\s?)?)49(\s|\s?-\s?)?(\(0\)[-\s]?)?|0)[1-9]((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])(((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]?[0-9]?[0-9]?[0-9])?$/,
  gb: /^((\+|00(\s|\s?-\s?)?)44(\s|\s?-\s?)?(\(0\)[-\s]?)?|0)[1-9]((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9](\s?[0-9]\s?[0-9]?[0-9])?$/
}
