import * as React from 'react'
import get from 'lodash/get'

import { IAddress } from '../../../../../../../models'
import { eventHandler } from '../../../../../../../utils'

import { ChatBotContext } from '../../../../../../store/chatbot'
import { ShipmentContext } from '../../../../../../store/shipment'

import { Translate } from '../../../../../../base/components'

import './styles.scss'

export const AddressNeighbourChange = () => {
  const { shipmentInfo } = React.useContext(ShipmentContext)
  const [loading, setLoading] = React.useState(false)
  const {
    changeAddress,
    updateNeighbour,
    resetChatActions,
    forNeighbourUpdate
  } = React.useContext(ChatBotContext)
  const [address, setAddress] = React.useState(
    get(shipmentInfo, 'recipient.address', '')
  )
  const [postalCode, setPostalCode] = React.useState(
    get(shipmentInfo, 'recipient.postalCode', '')
  )
  const [neighbourName, setNeighbourName] = React.useState('')
  const city = get(shipmentInfo, 'recipient.city', '')
  const country = get(shipmentInfo, 'recipient.country', '')

  const handleSubmitMessage = async () => {
    setLoading(true)
    const nextAddress: IAddress = {
      ...{
        address,
        postalCode,
        city,
        country
      },
      ...(forNeighbourUpdate && { neighbourName })
    }

    await (forNeighbourUpdate
      ? updateNeighbour(nextAddress)
      : changeAddress(nextAddress))
    setLoading(false)
  }

  const isSubmitButtonDisabled =
    !address.length || (forNeighbourUpdate && !neighbourName.length)

  return (
    <div className="neighbour-form-container">
      <div className="neighbour-form">
        <div className="neighbour-form-query">
          <Translate>NEIGHBOUR_FORM_QUERY</Translate>
        </div>
        {forNeighbourUpdate && (
          <div className="neighbour-name">
            <div className="name">
              <div className="neighbour-label">
                <Translate>NEIGHBOUR_NAME</Translate>
              </div>
              <input
                value={neighbourName}
                onChange={eventHandler(setNeighbourName)}
                className="input neighbour-name-input"
                type="text"
              />
            </div>
          </div>
        )}
        <div className="neighbour-street-nr">
          <div className="street">
            <div className="neighbour-label">
              <Translate>STREET</Translate>
            </div>
            <input
              value={address}
              onChange={eventHandler(setAddress)}
              className="input street-input"
              type="text"
            />
          </div>
        </div>
        <div className="neighbour-postal-code">
          <div className="postal-code">
            <div className="neighbour-label">
              <Translate>POSTAL_CODE_LABEL</Translate>
            </div>
            <input
              value={postalCode}
              onChange={eventHandler(setPostalCode)}
              className="input postal-code-input"
              type="text"
            />
          </div>
        </div>
        <div className="neighbour-city">
          <div className="city">
            <div className="neighbour-label">
              <Translate>CITY_NEIGHBOUR_FORM</Translate>
            </div>
            <input
              value={city}
              readOnly={true}
              className="input city-input"
              type="text"
            />
          </div>
        </div>
        <div className="neighbour-form-actions">
          <div className="col-sm">
            <button className="btn-cancel" onClick={resetChatActions}>
              <Translate>CANCEL</Translate>
            </button>
          </div>
          <div className="send col-sm">
            {!loading ? (
              <button
                disabled={isSubmitButtonDisabled}
                className={`btn-send flat-btn green ${!isSubmitButtonDisabled &&
                  'enabled'}`}
                onClick={handleSubmitMessage}
              >
                <Translate>SEND</Translate>
              </button>
            ) : (
              <div className="fa fa-circle-o-notch fa-spin change-address-loading" />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
