import * as React from 'react'
import { useAuthActions } from './actions'
import { IAuthState, initialState } from './state'
// export const ErrorHandlerContext = React.createContext({} as IErrorHandlerState)

export const AuthContext = React.createContext({} as IAuthState)
export default ({ children }: { children: React.ReactNode }) => {
  const state = useAuthActions(initialState)

  AuthContext.displayName = 'Auth'

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>
}
