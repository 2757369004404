import * as React from 'react'
// @ts-ignore
import { CopyToClipboard } from 'react-copy-to-clipboard'
import clsx from 'clsx'

import { Translate } from '../translate'

import './sharelink.scss'

interface IShareLinkProps {
  link: string
}

interface IShareLinkState {
  isCopied: boolean
  isLinkVisible: boolean
}

export class ShareLink extends React.Component<
  IShareLinkProps,
  IShareLinkState
> {
  constructor(props: IShareLinkProps) {
    super(props)
    this.state = {
      isCopied: false,
      isLinkVisible: false
    }
  }

  public toggleLinkUrl = () => {
    const { isLinkVisible } = this.state
    this.setState(() => ({
      isLinkVisible: !isLinkVisible,
      isCopied: false
    }))
  }

  public handleCopy = () => {
    this.setState(() => ({
      isCopied: true
    }))
  }

  public render() {
    const { link } = this.props
    const { isCopied, isLinkVisible } = this.state

    return (
      <div className="share-link d-none d-md-inline-flex d-lg-inline-flex">
        <span className="icon">
          <i className="material-icons">share</i>
        </span>
        <span className="label" onClick={this.toggleLinkUrl}>
          <Translate>SHARE_PAGE</Translate>
        </span>
        <span id="share-link-url" className={clsx({ active: isLinkVisible })}>
          <span className="link">{link}</span>
          <CopyToClipboard text={link} onCopy={this.handleCopy}>
            <span className={clsx('copy-button', { copied: isCopied })}>
              <Translate>{isCopied ? 'COPIED' : 'COPY'}</Translate>
            </span>
          </CopyToClipboard>
        </span>
      </div>
    )
  }
}
