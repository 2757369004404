export enum DAYS {
  MONDAY = 'Maandag',
  TUESDAY = 'Dinsdag',
  WEDNESDAY = 'Woensdag',
  THURSDAY = 'Donderdag',
  FRIDAY = 'Vrijdag',
  MAANDAG = 'Monday',
  DINSDAG = 'Tuesday',
  WOENSDAG = 'Wednesday',
  DONDERDAG = 'Thursday',
  VRIJDAG = 'Friday'
}
