import { sign, verify, VerifyErrors } from 'jsonwebtoken'
import isEmpty from 'lodash/isEmpty'

import { IUser } from '../../models'

const config = process.env
const TRUNKRS_APP_TOKEN = config.REACT_APP_TOKEN_NAME as string
const TOKEN_SECRET = config.REACT_APP_TOKEN_SECRET as string

export class TokenAuthentication {
  public authenticate = (): boolean => {
    const token = sessionStorage.getItem(TRUNKRS_APP_TOKEN) as string
    let result = false
    if (!isEmpty(token)) {
      result = this.verifyToken(token)
    }

    return result
  }

  public generateToken = (user: IUser): string => sign(user, TOKEN_SECRET)

  public getPayload = (): IUser | string | object => {
    const token = this.getToken() || ''

    let payload: IUser | string | object = ''

    if (!isEmpty(token)) {
      verify(
        token,
        TOKEN_SECRET,
        (err: VerifyErrors, decoded: object | string) => {
          payload = decoded
        }
      )
    }

    return payload
  }

  public verifyToken = (token: string): boolean => {
    let result = false

    verify(token, TOKEN_SECRET, (err: VerifyErrors) => {
      if (err) {
        throw err
      } else {
        result = true
      }
    })

    return result
  }

  public getToken = () => sessionStorage.getItem(TRUNKRS_APP_TOKEN)

  public saveToken = (token: string) =>
    sessionStorage.setItem(TRUNKRS_APP_TOKEN, token)

  public removeToken = () => sessionStorage.removeItem(TRUNKRS_APP_TOKEN)
}
