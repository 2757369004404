import * as React from 'react'
import * as Sentry from '@sentry/browser'
import generateId from 'lodash/uniqueId'

import { IChatOption } from '../../../../../../../models'
import { ChatBotContext } from '../../../../../../store/chatbot'

import { TypeSpinner } from '../../../../../../base/components'

import './styles.scss'

export const MessageInputs = () => {
  const [loading, setLoading] = React.useState(false)
  const { chatOptions, sendMessage } = React.useContext(ChatBotContext)

  const handleSubmitOption = (value: string, text: string) => () => {
    setLoading(true)
    sendMessage(value, text)
      .then(() => setLoading(false))
      .catch(error => {
        Sentry.captureException(error)
        setLoading(false)
      })
  }

  return (
    <div className="message-input">
      <div className="write-container">
        <div className={`button-wrapper`} id="button-wrapper-container">
          {loading ? (
            <TypeSpinner />
          ) : (
            (chatOptions as IChatOption[]).map(({ text, value }, index) => {
              return (
                <button
                  key={generateId('chatOption_')}
                  onClick={handleSubmitOption(value, text)}
                  className={`write-button slide-left-${index}`}
                >
                  {text}
                </button>
              )
            })
          )}
        </div>
      </div>
    </div>
  )
}
