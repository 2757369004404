export default {
  APP_NAME: 'Trunkrs Track & Talk',
  WELCOME: 'welkom',
  LOGIN: 'inloggen',
  USERNAME: 'gebruikersnaam',
  EMAIL: 'e-mail adres',
  PASSWORD: 'wachtwoord',
  RESTORE_PASSWORD: 'wachtwoord vergeten?',
  SEND_EMAIL: 'Wachtwoord verzenden',
  SEND_EMAIL_MSG:
    'Vul uw E-mail adres in en wij verzenden u een nieuw wachtwoord.',
  EMAIL_SENT_MSG:
    'Wij hebben u een nieuw wachtwoord gestuurd. Nadat u bent ingelogged, met uw nieuwe wachtwoord, kunt u uw wachtwoord wijzigen op uw profiel pagina.',
  BACK: 'Terug',
  LOGOUT: 'uitloggen',
  ADDRES: 'Adres',
  HOUSE_NUMBER: 'huisnummer',
  POSTAL_CODE: 'Postcode',
  SEARCH_TEXT: 'Welkom bij',
  SEARCH_DESC: 'Je pakket volgen is nog nooit zo makkelijk geweest!',
  TRUNKRS_NR: 'Trunkrsnummer',
  CANT_FIND: 'Help, ik kan mijn zending niet vinden',
  CONTACT_US: 'Neem contact met ons op via de live chat rechts onderin',
  SEARCH: 'Zoek',
  SEARCH_ERR:
    'We kunnen uw zending niet vinden o.b.v. de ingevoerde gegevens. Wijzig uw invoer en probeer opnieuw.',
  CITY: 'stad',
  COUNTRY: 'land',
  STATE: 'status',
  TOUR: 'route',
  DRIVER: 'chauffeur',
  THE_NETHERLANDS: 'Nederland',
  GERMANY: 'Duitsland',
  TYPE_MSG: 'Stel hier uw vraag...',
  ETA: 'Aankomsttijd',
  SETTING_NOTIFICATIONS: 'Desktop notificaties',
  SETTING_PRIORITY: 'Enkel belangrijke berichten',
  LOADING: 'Een ogenblik geduld',
  LOADING_MSG1: 'Bergen aan het verschuiven speciaal voor jou!',
  LOADING_MSG2: 'Zes bananen + negen dropjes = jouw pakketje!',
  LOADING_MSG3:
    'Drie keer knipperen en jouw pakketje is gevonden, probeer maar!',
  LOADING_MSG4: 'A B C D E F G H I J K L M N O Pakketje aan het zoeken…',
  LOADING_MSG5:
    'Mars, Jupiter én Saturnus staan vandaag op één lijn, je pakketje zou zo gevonden moeten zijn!',
  LOADING_MSG6:
    'Satelieten worden geherpositioneerd om jouw pakketje te lokaliseren...',
  LOADING_MSG7: 'Plata o pakketje?!',
  LOADING_MSG8: 'Uw zending wordt opgehaald.',
  LOADING_MSG9: 'Uw zending wordt opgehaald.',
  LOADING_MSG10: 'Uw zending wordt opgehaald.',
  FEEDBACK_PLACEHOLDER: 'waarom?',
  PUSH_ENABLED_TITLE: 'Aangemeld',
  PUSH_ENABLED_BODY: 'Je bent aangemeld voor push notificaties',
  STATE_1: 'In magazijn',
  STATE_2: 'In magazijn',
  STATE_3: 'In magazijn',
  STATE_4: 'In magazijn',
  STATE_5: 'Onderweg',
  STATE_6: 'Onderweg',
  STATE_7: 'Ben er bijna!',
  STATE_8: 'In magazijn',
  STATE_9: 'In magazijn',
  STATE_10: 'Bezorgd',
  STATE_11: 'Bezorgd bij een buur',
  STATE_12: 'Berzorging mislukt :(',
  STATE_13: 'Onderweg',
  STATE_14: 'Onderweg',
  STATE_15: 'Onderweg',
  STATE_16: 'Onderweg',
  STATE_17: 'Zending is geannuleerd',
  STATE_18: 'Onderweg',
  UT_NEXT: 'Volgende',
  UT_FINISH: 'Klaar',
  UT_1_TITLE: 'Welkom',
  UT_1_TEXT: 'Hier vind je de te verwachten aankomsttijd van je pakketje.',
  UT_2_TITLE: 'Berichten van je pakketje',
  UT_2_TEXT: 'Dit is je pakketje, het vertelt je alles wat je wil weten.',
  UT_3_TITLE: 'Feedback geven',
  UT_3_TEXT: 'Ben je blij of minder blij met een bericht, laat het weten',
  UT_3_TITLE_M: 'Feedback geven',
  UT_3_TEXT_M: 'Je kan ook feedback geven door op het bericht te tikken',
  UT_4_TITLE: 'Aan de slag!',
  UT_4_TEXT: 'Hier kan je vragen stellen, dus vraag maar raak!',
  EXPECTED: 'Verwacht',
  IN_WAREHOUSE: 'Bij Trunkrs',
  ON_THE_WAY: 'Onderweg',
  ALMOST_THERE: 'Dichtbij',
  DELIVERED: 'Afgeleverd',
  DELIVERED_TO_NEIGHBOUR: 'Afgeleverd bij buur',
  NOT_DELIVERED: 'Niet afgeleverd',
  CANCELED: 'Geannuleerd',
  RETURNED_TO_SENDER:
    'Jouw pakket is geannuleerd, neem contact op met de verzender.',
  DELIVERY: 'Aflevering',
  TODAY: 'Vandaag',
  TOMORROW: 'Morgen',
  RECIPIENT: 'Ontvanger',
  SENDER: 'Verzender',
  THE_SENDER: 'De verzender',
  AT_YOUR_NEIGHBOURS: 'Bij je buur',
  ON: 'op',
  LOGIN_ERR_MSG_1:
    'Kun je je pakket niet vinden en weet je zeker dat je de juiste combinatie van Trunkrsnummer en postcode hebt gebruikt? Neem dan ',
  LOGIN_ERR_MSG_2: '',
  LOGIN_ERR_MSG_3: 'contact met ons op.',
  VISIT_WEBSITE: 'Bezoek Website',
  EXPECTED_DELIVERY: 'Verwachte levering',
  CANCELED_DELIVERY_TRUNKRS: 'De zending is geannuleerd.',
  CANCELED_DELIVERY_SENDER: 'De zending is geannuleerd.',
  NOT_ARRIVED: 'De zending is niet bij Trunkrs aangekomen',
  SIGN_OUT: 'Afmelden',
  MADE_REQUEST_FOR_DELIVERY: 'heeft jouw pakket aangemeld bij Trunkrs',
  PARCEL_DELIVERED_TO_WAREHOUSE:
    'Jouw pakket is aangekomen in ons centrale warehouse',
  DRIVER_ON_THE_WAY: 'Onze driver is onderweg om jouw pakket te bezorgen',
  DRIVER_IS_CLOSE:
    'Onze driver is erg dichtbij, je kunt de deurbel elk moment verwachten',
  PLEASE_TYPE_MESSAGE: 'Voer hoofdletters in:',
  TO_CONFIRM_MESSAGE:
    'om te bevestigen dat u wilt dat jouw pakket wordt afgeleverd',
  POSTPONE_SUCCEEDED_MESSAGE_1: 'Super!',
  POSTPONE_SUCCEEDED_MESSAGE_2: 'Wij bezorgen jouw pakket morgen / maandag',
  NEIGHBOUR_FORM_QUERY: 'Aan welke buur wilt u dat wij jouw pakket bezorgen?',
  STREET: 'STRAAT',
  HOUSE_NR: 'HUIS NR.',
  CITY_NEIGHBOUR_FORM: 'STAD',
  SEND: 'STUREN',
  ADDRESS_CHANGE_SUCCEEDED_MESSAGE_1: 'OKE!',
  ADDRESS_CHANGE_SUCCEEDED_MESSAGE_2:
    'Ik heb onze chauffeur verteld dat hij bij je buren moet bezorgen',
  DELIVERY_ADDRESS_LABEL: 'Nieuw afleveradres',
  PARCEL_INFORMATION: 'Verzendinformatie',
  TEXT_MESSAGE: 'SMS-berichtmeldingen',
  TEXT_MESSAGE_REG:
    'Wilt u altijd op de hoogte blijven van uw levering? Laat uw telefoonnummer achter en we sturen u een sms-bericht voor elke update!',
  TEXT_MESSAGE_UPDATE:
    "Je hebt al een telefoonnummer aangemeld. Wil je het nummer wijzigen? Vul het nieuwe nummer in en druk op 'opslaan'.",
  SAVE: 'OPSLAAN',
  ONBOARDING_GREETING_1: 'Welkom bij Trunkrs Track & Talk',
  ONBOARDING_GREETING_2:
    'Je pakketje volgen was nog nooit zó gemakkelijk! We laten je graag even zien wat je kunt verwachten.',
  ONBOARDING_STATUS_1: 'Altijd zicht op jouw bezorging',
  ONBOARDING_STATUS_2:
    'Met onze live status kun je tot aan jouw voordeur zien waar jouw pakketje nu is en waar het heen gaat.',
  ONBOARDING_TIME_1: 'Altijd up-to-date bezorgtijden',
  ONBOARDING_TIME_2:
    'Van wachten wordt niemand blij, daarom laten we je liever niet onnodig lang wachten. Onze bezorgtijden zijn zo precies mogelijk, zodat jij al die andere tijd aan leuke dingen kunt besteden.',
  ONBOARDING_CHAT_1: 'Onze bezorgassistent tot je beschikking',
  ONBOARDING_CHAT_2:
    'Heb je een vraag, wil je jouw pakketje liever bij de buren laten bezorgen of is er iets waarmee we je kunnen helpen? Vraag het aan onze bezorgassistent!',
  ONBOARDING_SMS_1: 'Blijf op de hoogte met sms-notificaties',
  ONBOARDING_SMS_2:
    'Laat je telefoonnummer achter en blijf altijd op de hoogte over jouw pakketje, ook als je even niet op deze pagina kunt kijken!',
  ONBOARDING_BACK: 'Terug',
  ONBOARDING_NEXT: 'Volgende',
  ONBOARDING_READY: 'Ik ben er klaar voor!',
  MORE: 'Meer...',
  CHANGE_ADDRESS_RETRY:
    'Zorg ervoor dat het adres dat je kiest, zich binnen 200 meter van het oorspronkelijke adres bevindt.',
  TRY_AGAIN: 'Probeer het nog eens',
  DELAY_DELIVERY_MESSAGE:
    'Het lijkt erop dat we wat vertraging in de route hebben. Geen zorgen, onze chauffeur is nog steeds onderweg!',
  NO_DELIVERY_DEFAULT:
    'Helaas is het ons vanavond niet gelukt om jouw pakketje af te leveren. We proberen het de volgende werkdag opnieuw. Onze excuses!',
  NO_DELIVERY_RECIPIENT_NOT_AT_HOME:
    'We stonden zojuist voor de deur, maar helaas deden zowel jij als de buren niet open. We proberen het de volgende werkdag opnieuw.',
  NO_DELIVERY_MISSORT:
    'Door een fout is jouw pakketje helaas in de verkeerde regio terecht gekomen. We proberen het de volgende werkdag opnieuw. Onze excuses.',
  NO_DELIVERY_BUSINESS_CLOSED:
    'Het adres dat je hebt opgegeven blijkt een zakelijk adres te zijn en was gesloten. Liever een ander adres? Bel onze klantenservice: 085 - 0601 410.',
  NO_DELIVERY_NO_TIME_IN_TIMESLOT:
    'Vanwege vertraging op de route is het helaas niet gelukt om je pakket af te leveren. We komen de volgende werkdag weer langs. Onze excuses.',
  NO_DELIVERY_EXTREME_WEATHER:
    'Vanwege extreme weersomstandigheden heeft onze chauffeur vanavond niet af kunnen leveren. We komen de volgende werkdag weer langs.',
  NO_DELIVERY_DRIVER_ACCIDENT:
    'Helaas is onze chauffeur betrokken geraakt bij een ongeval en de route niet voltooien. We komen de volgende werkdag weer langs.',
  NO_DELIVERY_REFUSED_BY_CUSTOMER:
    'Vanavond heb je een pakketje van onze chauffeur afgewezen. We vinden het jammer dat dit gebeurd is 😞.',
  NO_DELIVERY_NOT_HANDED_OVER_1:
    'Helaas is jouw pakket vandaag niet aangeboden ter verzending door',
  NO_DELIVERY_NOT_HANDED_OVER_2:
    'Wij adviseren contact met hen op te nemen, zij kunnen je vertellen wanneer jouw pakket ter verzending aangeboden zal worden bij ons.',

  STATUS_DESC_DEFAULT:
    'Helaas is het niet gelukt om jouw pakketje af te leveren.',
  STATUS_DESC_RECIPIENT_NOT_AT_HOME:
    'We stonden voor de deur, maar helaas deden zowel jij als de buren niet open.',
  STATUS_DESC_MISSORT:
    'Door een fout is jouw pakketje helaas in de verkeerde regio terecht gekomen.',
  STATUS_DESC_BUSINESS_CLOSED:
    'Het adres dat je hebt opgegeven blijkt een zakelijk adres te zijn en was gesloten.',
  STATUS_DESC_NO_TIME_IN_TIMESLOT:
    'Vanwege vertraging op de route is het helaas niet gelukt om je pakket af te leveren.',
  STATUS_DESC_EXTREME_WEATHER:
    'Vanwege extreme weersomstandigheden heeft onze chauffeur vanavond niet af kunnen leveren.',
  STATUS_DESC_DRIVER_ACCIDENT:
    'Helaas is onze chauffeur betrokken geraakt bij een ongeval en de route niet voltooien.',
  STATUS_DESC_REFUSED_BY_CUSTOMER:
    'Je hebt het pakketje van onze chauffeur afgewezen. We vinden het jammer dat dit gebeurd is 😞.',
  SHIPPING_HISTORY: 'Verzendhistorie',
  CLOSE: 'Sluiten',
  SEE_SHIPPING_HISTORY: 'Bekijk verzendhistorie',
  RETURN_TO_SENDER_NOTIFICATION:
    'Helaas hebben we je pakket 3 maal niet bij je af kunnen leveren. We hebben het retour gestuurd naar ',
  SHARE_PAGE: 'Deel deze pagina',
  COPY: 'kopiëren',
  COPIED: 'gekopieerd!',
  CANCEL: 'Annuleren',
  POSTAL_CODE_LABEL: 'POSTAL CODE',
  NEIGHBOUR_NAME: 'BUUR NAME',
  HELP_US_IMPROVE: 'HELP ONS BETER TE WORDEN',
  HOW_DID_YOU_EXPERIENCE_OUR_SERVICE: 'Hoe heb je onze dienst ervaren?',
  IT_WAS_GREAT: 'Het was geweldig',
  COULD_BE_BETTER: 'Het kon beter',
  THANK_YOU_FOR_HELPING: 'BEDANKT VOOR JE HULP!',
  WE_HAVE_SAVED_YOUR_VOTE:
    ' We hebben jouw mening ontvangen en gebruiken deze om nog beter te worden in wat we doen!',
  YOUR_VOTE_ABOUT_OUR_SERVICE: 'Jouw mening over onze service:'
}
