import * as React from 'react'
import clsx from 'clsx'
import get from 'lodash/get'

import { ITrackTraceInformation } from 'Trunkrs-SDK/dist/models/communications'

import { INIT_URL } from '../../../../../../constants'
import { ShipmentContext } from '../../../../../store/shipment'

import { Translate } from '../../../../../base/components'

export const ParcelInfo = (props: { headerExpanded: boolean }) => {
  const { headerExpanded } = props
  const { shipmentInfo } = React.useContext(ShipmentContext)
  const { parcel, recipient, sender } = shipmentInfo as ITrackTraceInformation

  return (
    <div id="parcel-info" className={clsx({ expand: headerExpanded })}>
      <h1>
        <Translate>PARCEL_INFORMATION</Translate>
      </h1>
      <div className="label">
        <p className="header">
          <Translate>TRUNKRS_NR</Translate>
        </p>
        <p className="value">{get(parcel, 'trunkrsNr', '')}</p>
      </div>
      <div className="label">
        <p className="header">
          <Translate>RECIPIENT</Translate>
        </p>
        <p className="value">{get(recipient, 'name', '')}</p>
      </div>
      <div className="label">
        <p className="header">
          <Translate>ADDRES</Translate>
        </p>
        <p className="value">
          {recipient && (
            <>
              <span key={'address'}>{recipient.address}</span>
              <span
                key={'postal-city'}
              >{`${recipient.postalCode} ${recipient.city}`}</span>
            </>
          )}
        </p>
      </div>
      <div className="label">
        <p className="header">
          <Translate>SENDER</Translate>
        </p>
        <p className="value">{get(sender, 'name', '')}</p>
      </div>
      <div className="links-container d-block d-md-none d-lg-none">
        <div className="links redirect">
          <a
            href="https://www.trunkrs.nl"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Translate>VISIT_WEBSITE</Translate>
          </a>
        </div>
        <div className="links sign-out">
          <a href={INIT_URL}>
            <Translate>SIGN_OUT</Translate>
          </a>
        </div>
      </div>
    </div>
  )
}
