import moment from 'moment'
import isEmpty from 'lodash/isEmpty'

export const isTomorrow = (d?: Date | string): boolean => {
  if (isEmpty(d)) {
    return false
  }
  const tomorrow = moment()
    .add(1, 'days')
    .startOf('day')
  return moment(d).isSame(tomorrow, 'd')
}
