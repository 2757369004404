import clsx from 'clsx'
import { get, last } from 'lodash'
import moment from 'moment'
import * as React from 'react'
import { ITrackTraceInformation } from 'Trunkrs-SDK/dist/models/communications'
import { IDailyMessageResponse } from 'Trunkrs-SDK/dist/models/communications/TrackAndTalk'
import { NOTIFICATION_TYPE, SHIPMENT_STATES } from '../../../../../../constants'
import { IShipmentHistory } from '../../../../../../models'

import {
  convertTimezone,
  isCanceled,
  isCanceledSender,
  isCanceledTrunkrs,
  isDelivered,
  isDeliveredToNeighbor,
  isFinalState,
  isNotArrived,
  isNotDelivered,
  isToday,
  isTomorrow
} from '../../../../../../utils'

import { Translate } from '../../../../../base/components'
import { ShipmentContext } from '../../../../../store/shipment'
import ServiceReview from './ServiceReview'

const notificationColor = {
  [NOTIFICATION_TYPE.info]: '#3dd5e5',
  [NOTIFICATION_TYPE.warning]: '#f59123',
  [NOTIFICATION_TYPE.error]: '#d32d4f'
}

const CORONA_MESSAGE_ID = 30

interface IShipmentNotification {
  icon: any
  color?: string
  description: string
}

interface IETAInfoTemplates {
  status: React.ReactNode
  etaDate: React.ReactNode
  etaTime: string
}

const getETATemplate = (state: SHIPMENT_STATES, shipmentInfo: any) => {
  if (isDeliveredToNeighbor(state, false)) {
    return (
      <span>
        <Translate>AT_YOUR_NEIGHBOURS</Translate>
        {` ${get(shipmentInfo, 'neighbour.name', '')} `}
        <Translate>ON</Translate> {get(shipmentInfo, 'neighbour.address', '')}
      </span>
    )
  }
  if (isDelivered(state, false)) {
    return <Translate>DELIVERED</Translate>
  }
  if (isNotDelivered(state, false)) {
    return <Translate>NOT_DELIVERED</Translate>
  }
  if (isCanceledTrunkrs(state, false)) {
    return <Translate>CANCELED_DELIVERY_TRUNKRS</Translate>
  }
  if (isCanceledSender(state, false)) {
    return <Translate>CANCELED_DELIVERY_SENDER</Translate>
  }
  if (isNotArrived(state, false)) {
    return <Translate>NOT_ARRIVED</Translate>
  }

  return <Translate>EXPECTED_DELIVERY</Translate>
}

const getShipmentNotifications = (
  shipment: ITrackTraceInformation,
  shipmentHistory: IShipmentHistory[],
  customerNotify: IDailyMessageResponse
): IShipmentNotification[] => {
  const notifications: IShipmentNotification[] = []

  const parcel = get(shipment, 'parcel')
  const currentState = get(
    parcel,
    'state.name',
    SHIPMENT_STATES.DATA_RECEIVED
  ) as SHIPMENT_STATES

  if (customerNotify) {
    const isAllowedToAddMessage = !isDelivered(currentState, false)
      || customerNotify.id !== CORONA_MESSAGE_ID

    if (isAllowedToAddMessage) {
      notifications.push({
        icon: <i className="material-icons">info</i>,
        color: notificationColor[NOTIFICATION_TYPE.info],
        description: customerNotify.message
      })
    }
  }

  const isLateDelivery =
    !isFinalState(currentState) &&
    !isNotArrived(currentState, false) &&
    moment().isAfter(get(parcel, 'estimation.to', ''))

  if (isNotDelivered(currentState, false)) {
    const notDeliveredMessageCode = get(parcel, 'state.reasonCode', 'DEFAULT')
    notifications.push({
      icon: <i className="material-icons">error_outline</i>,
      color: notificationColor[NOTIFICATION_TYPE.error],
      description: Translate.translate(`NO_DELIVERY_${notDeliveredMessageCode}`)
    })
  } else if (isLateDelivery) {
    notifications.push({
      icon: '⏰',
      description: Translate.translate('DELAY_DELIVERY_MESSAGE')
    })
  }

  const failedDeliveryForThreeTimes =
    shipmentHistory.filter(history => history.isNotDelivered).length >= 3

  if (failedDeliveryForThreeTimes) {
    const senderName = get(
      shipment,
      'sender.name',
      Translate.translate('THE_SENDER')
    )

    notifications.push({
      icon: '🔴',
      color: notificationColor[NOTIFICATION_TYPE.error],
      description: `${Translate.translate(
        'RETURN_TO_SENDER_NOTIFICATION'
      )} ${senderName}`
    })
  }

  return notifications
}

const getETAInformationTemplates = (
  shipment: ITrackTraceInformation,
  shipmentHistory: IShipmentHistory[]
): IETAInfoTemplates => {
  const currentState = get(
    shipment,
    'parcel.state.name',
    SHIPMENT_STATES.DATA_RECEIVED
  ) as SHIPMENT_STATES
  const estimationFrom = convertTimezone(get(shipment, 'parcel.estimation.from', new Date()))
  const estimationTo = convertTimezone(get(shipment, 'parcel.estimation.to', new Date()))
  let estimationDay = moment(estimationFrom, 'YYYY-MM-DD').toDate()
  const latestLog = last(shipmentHistory)

  let exactDeliveryTime: Date | null
  if (latestLog && isDelivered(latestLog.currentState, false)) {
    const deliveredState = latestLog.states.find(state =>
      isDelivered(state.name as SHIPMENT_STATES, false)
    )
    if (deliveredState) {
      exactDeliveryTime = deliveredState.timestamp
      estimationDay = moment(exactDeliveryTime!).toDate()
    }
  }

  let etaTime =  '';

  if(isFinalState(currentState) && exactDeliveryTime!){
    etaTime = moment(exactDeliveryTime!).format('HH:mm')
  }
  else if(moment(estimationFrom).isBefore(moment({h: 17})) && moment(estimationFrom).isSame(moment(), 'day')){
    etaTime = '17:00 - 18:30'
  }
  else if(moment(estimationTo).isAfter(moment({h: 22, m: 30})) && moment(estimationFrom).isSame(moment(), 'day')){
    etaTime = '21:00 - 22:30'
  }
  else {
    etaTime = `${moment(estimationFrom).format('HH:mm')} - ${moment(estimationTo).format('HH:mm')}`
  }

  return {
    status: getETATemplate(currentState, shipment),
    etaDate: (
      <span>
        <Translate>{`${
          isToday(estimationDay)
            ? 'TODAY'
            : isTomorrow(estimationDay)
            ? 'TOMORROW'
            : ''
        }`}</Translate>{' '}
        {`${moment(estimationDay).format('D MMMM')}`}
      </span>
    ),
    etaTime
  }
}

export const ParcelETAInfo = () => {
  const {
    shipmentInfo,
    notification: customerNotification,
    shipmentHistory
  } = React.useContext(ShipmentContext)
  const currentState = get(
    shipmentInfo,
    'parcel.state.name',
    SHIPMENT_STATES.DATA_RECEIVED
  ) as SHIPMENT_STATES

  const ETA_INFO_TEMPLATES = React.useMemo(
    () => getETAInformationTemplates(shipmentInfo!, shipmentHistory),
    [shipmentInfo!, shipmentHistory] // eslint-disable-line react-hooks/exhaustive-deps
  )
  const notificationList = React.useMemo(
    () =>
      getShipmentNotifications(
        shipmentInfo!,
        shipmentHistory,
        customerNotification!
      ),
    [shipmentInfo!, shipmentHistory, customerNotification!] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const infoClassNames = React.useMemo(
    () =>
      clsx({
        'not-delivered': isNotDelivered(currentState, false),
        canceled:
          isCanceled(currentState, false) || isNotArrived(currentState, false),
        delivered: isDelivered(currentState, false)
      }),
    [currentState]
  )

  return (
    <div id="parcel-eta-info-container" className="col-8">
      <div className="d-flex align-items-center justify-content-center h-100">
        <div id="eta-info" className={infoClassNames}>
          <div className="label">
            <p className="header">{ETA_INFO_TEMPLATES.status}</p>
            {!isCanceled(currentState, false) &&
              !isNotArrived(currentState, false) && (
                <>
                  <p className="value">{ETA_INFO_TEMPLATES.etaDate}</p>
                  <p className="value">{ETA_INFO_TEMPLATES.etaTime}</p>
                </>
              )}
          </div>

          <div className="panel-notification-container">
            {notificationList.map(({ icon, description, color }, index) => (
              <div
                key={`panel-notification-${index}`}
                className="panel-notification"
                style={{ color }}
              >
                <span className="icon">{icon}</span>
                <span className="description">{description}</span>
              </div>
            ))}
          </div>

          {isDelivered(currentState, false) && (
            <div className="panel-service-review-container">
              <ServiceReview />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
