import * as Raven from 'raven-js'
import md5 from 'md5'
import isEmpty from 'lodash/isEmpty'

import { IUser } from '../../models'
import { TokenAuthentication } from '../../utils'

const auth = new TokenAuthentication()

export class UserContext {
  public create = (user: IUser) => {
    const userToken = auth.generateToken(user)
    if (!isEmpty(userToken)) {
      auth.saveToken(userToken)
      Raven.setUserContext(user as any)
    }
  }

  public getUser = (): IUser => auth.getPayload() as IUser

  public reset = () => {
    auth.removeToken()
    Raven.setUserContext({})
  }

  public createUserToken = (
    id: string,
    trunkrsNr: string,
    postalCode: string
  ) => {
    let input: string = `${trunkrsNr}${postalCode}`
    input = input.replace(/\s/g, '')
    input = input.toLowerCase()
    input = md5(input)
    this.create({ id, trunkrsNr, token: input })
  }
}
