import * as React from 'react'
import defaultTo from 'lodash/defaultTo'
import last from 'lodash/last'

import { eventHandler } from '../../../../../../utils'
import { SHIPMENT_STATES } from '../../../../../../constants'

import { ShipmentContext } from '../../../../../store/shipment'
import { Translate } from '../../../../../base/components'

import { TimelineProgress } from './StatusTimeline'
import { ShippingHistory } from './ShippingHistory'

const { useState, useContext } = React

export const ParcelStatus = () => {
  const { shipmentHistory } = useContext(ShipmentContext)
  const [
    shippingHistoryModalVisible,
    setShippingHistoryModalVisible
  ] = useState(false)

  const latestShipmentHistory = defaultTo(last(shipmentHistory), {
    states: [],
    date: new Date(),
    currentState: SHIPMENT_STATES.DATA_RECEIVED
  })

  return (
    <div id="parcel-status">
      <div className={'parcel-title'}>
        <h1>STATUS</h1>
        <span
          onClick={eventHandler(
            setShippingHistoryModalVisible,
            !shippingHistoryModalVisible
          )}
          className="modal-link"
        >
          <Translate>SEE_SHIPPING_HISTORY</Translate>
        </span>
      </div>
      <TimelineProgress shipmentHistory={latestShipmentHistory} />
      {shippingHistoryModalVisible && (
        <ShippingHistory
          handleClose={eventHandler(setShippingHistoryModalVisible, false)}
        />
      )}
    </div>
  )
}
