import {
  ITrackTraceInformation,
  TrackAndTalk
} from 'Trunkrs-SDK/dist/models/communications'
import {
  IDailyMessageResponse,
  IShipmentLog
} from 'Trunkrs-SDK/dist/models/communications/TrackAndTalk'

import { IIdentity, IShipmentHistory } from '../../../models'

export interface IShipmentState {
  shipmentInfo: ITrackTraceInformation | null
  notification: IDailyMessageResponse | null
  shipmentHistory: IShipmentHistory[]
  fetchShipmentInfo: (identity: IIdentity) => Promise<TrackAndTalk | null>
  fetchShipmentHistory: (
    identity: IIdentity,
    shipmentLogs: IShipmentLog[]
  ) => Promise<IShipmentHistory[] | void>
  fetchAllShipmentInfo: (identity: IIdentity) => Promise<void>
  subscribeToSMS: (phoneNumber: string) => Promise<boolean>
}

export const initialState: IShipmentState = {
  shipmentInfo: null,
  notification: null,
  shipmentHistory: [],
  fetchShipmentInfo: () => Promise.resolve(null),
  fetchShipmentHistory: () => Promise.resolve(),
  fetchAllShipmentInfo: () => Promise.resolve(),
  subscribeToSMS: () => Promise.resolve(false)
}
