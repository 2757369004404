import { TrunkrsSDK } from 'Trunkrs-SDK/dist'

const OPTIONS = {
  cache: true,
  cacheInvalidateAfter: 15,
  maxRetryAttempts: 2,
  mode: (process.env.REACT_APP_API_TARGET || 'DEVELOPMENT') as any,
  store: localStorage
}

const API = new TrunkrsSDK(OPTIONS)
export default API
