import * as React from 'react'
import './typespinner.css'

export const TypeSpinner = () => {
  return (
    <div className="type-spinner">
      <div className="blob" />
      <div className="blob" />
      <div className="blob" />
    </div>
  )
}
