import { useState } from 'react'

import defaultTo from 'lodash/defaultTo'
import get from 'lodash/get'
import findLast from 'lodash/findLast'
import findLastIndex from 'lodash/findLastIndex'

import API from '../../../api/v2'
import {
  IIdentity,
  Message,
  Conversation,
  Option,
  IChatOption,
  IAddress,
  DELIVERY_CHANGES
} from '../../../models'
import { unwrapResponse } from '../../hooks'

import { IChatBotState } from './state'

export const useChatBotActions = (initialState: IChatBotState) => {
  const conversation = API.Conversation()
  const [chatBot, setChatBot] = useState<Conversation>()
  const [chatOptions, setChatOptions] = useState<IChatOption[]>(
    initialState.chatOptions
  )
  const [forAddressChange, setForAddressChange] = useState(false)
  const [forNeighbourUpdate, setForNeighbourUpdate] = useState(false)
  const initMessage = 'main-intent'

  const [messageHistory, setMessageHistory] = useState<Message[]>(
    initialState.messageHistory
  )

  const initChatBot = async ({ trunkrsNr, postalCode }: IIdentity) => {
    if (trunkrsNr && postalCode) {
      const [, chatBotInstance] = await unwrapResponse(
        conversation.setup(trunkrsNr, postalCode)
      )

      if (chatBotInstance) {
        setChatBot(chatBotInstance)
        const messages = getMessages(chatBotInstance)
        if (!messages.length) {
          await sendMessage(initMessage, undefined, chatBotInstance)
        } else {
          getChatOptions(messages)
        }
      }
    }
  }

  const getMessages = (instance = chatBot) => {
    if (instance) {
      const nextMessageHistory = filterMessages(instance.getMessageHistory)
      setMessageHistory(nextMessageHistory)
      return nextMessageHistory
    }

    return []
  }

  const sendMessage = async (
    message: string,
    displayText?: string,
    instance = chatBot
  ) => {
    if (instance) {
      const [, response] = await unwrapResponse(
        instance.sendMessage(message, displayText)
      )

      if (response) {
        checkForChatBotResponse(response)
      }
    }
  }

  const changeAddress = async (location: IAddress, instance = chatBot) => {
    if (instance) {
      const [, response] = await unwrapResponse(
        instance.sendAction(location, DELIVERY_CHANGES.ADDRESS_CHANGE)
      )

      if (response) {
        checkForChatBotResponse(response)
      }
    }
    resetChatActions()
  }

  const updateNeighbour = async (location: IAddress, instance = chatBot) => {
    if (instance) {
      const [, response] = await unwrapResponse(
        instance.sendAction(location, DELIVERY_CHANGES.NEIGHBOUR_DELIVERY)
      )

      if (response) {
        checkForChatBotResponse(response)
      }
    }
    resetChatActions()
  }

  const checkForChatBotResponse = (response: any) => {
    if (Array.isArray(response)) {
      const nextMessageHistory = filterMessages([
        ...messageHistory,
        ...response
      ])
      setMessageHistory(nextMessageHistory)
      getChatOptions(nextMessageHistory)
    } else {
      checkForChatActions(response)
    }
  }

  const checkForChatActions = (response: any) => {
    const chatActionType = get(response, 'type')
    if (chatActionType === 'MODAL_INDICATOR') {
      const modalType = get(response, 'modal')
      if (modalType) {
        resetChatActions()
        if (modalType === 'ADDRESS_CHANGE') {
          setForAddressChange(true)
        } else if (modalType === 'NEIGHBOUR_DELIVERY') {
          setForNeighbourUpdate(true)
        }
      }
    }
  }

  const getChatOptions = (messages = messageHistory) => {
    const lastChatBotMessage = [
      ...messages.filter(
        ({ getUserType: userType }: Message) => userType === 'bot'
      )
    ].pop()

    if (lastChatBotMessage) {
      const nextOptions = defaultTo(
        lastChatBotMessage.getOptions,
        []
      ) as Option[]

      const nextChatOptions = nextOptions.map(
        (option: Option) =>
          ({ text: option.getText, value: option.getValue } as IChatOption)
      )
      setChatOptions(nextChatOptions)
    }
  }

  const filterMessages = (messages: Message[]) => {
    const nextMessages = defaultTo(messages, []).filter(
      (message: Message) => message.getMessage !== initMessage
    )
    const botLastResponse = findLast(
      nextMessages,
      (message: Message) => message.getUserType === 'bot'
    )

    if (botLastResponse && !botLastResponse.getOptions.length) {
      const lastIndex = findLastIndex(
        nextMessages,
        ({ getUserType: userType, getOptions: options }: Message) =>
          userType === 'bot' && options.length > 0
      )
      if (lastIndex > -1) {
        return nextMessages.slice(0, lastIndex + 1)
      }
    }
    return nextMessages
  }

  const resetChatActions = () => {
    setForAddressChange(false)
    setForNeighbourUpdate(false)
  }

  return {
    initChatBot,
    messageHistory,
    sendMessage,
    chatOptions,
    getChatOptions,
    forAddressChange,
    forNeighbourUpdate,
    changeAddress,
    updateNeighbour,
    resetChatActions
  }
}
