import * as React from 'react'
import clsx from 'clsx'

import { PolySpinner, Translate } from '..'

import './loading.css'

interface ILoadingProps {
  loading: boolean
}

export class Loading extends React.Component<ILoadingProps, {}> {
  public render() {
    const { loading } = this.props
    const randomNumber = Math.min(
      7,
      Math.max(0, Math.floor(Math.random() * 10 + 1))
    )

    return (
      <div className={clsx('full-page', { active: loading })}>
        <div className="helper" />
        <div className="loading-message">
          <div>
            <PolySpinner />
            <h3 className="message">
              <Translate>{`LOADING_MSG${randomNumber}`}</Translate>
            </h3>
          </div>
        </div>
      </div>
    )
  }
}
