import get from 'lodash/get'

import {
  SHIPMENT_GENERIC_STATES,
  SHIPMENT_STATES
} from '../../../../../../constants'
import { IProgress, IShipmentHistory } from '../../../../../../models'
import {
  convertToGenericState,
  isDelivered,
  isExpected,
  isFinalState as checkIfFinalState,
  isGenericFailureState,
  isInWarehouse,
  isOnTheWay
} from '../../../../../../utils'

export const getFinalStatusLabel = (
  genericState: SHIPMENT_GENERIC_STATES,
  reasonCode?: string
): [string, string] => {
  const {
    DELIVERED_TO_NEIGHBOR,
    NOT_DELIVERED,
    CANCELED
  } = SHIPMENT_GENERIC_STATES

  switch (genericState) {
    case DELIVERED_TO_NEIGHBOR:
      return ['DELIVERED_TO_NEIGHBOUR', '']
    case NOT_DELIVERED:
      return ['NOT_DELIVERED', `STATUS_DESC_${reasonCode}`]
    case CANCELED:
      return ['CANCELED', 'RETURNED_TO_SENDER']
    default:
      return ['DELIVERED', '']
  }
}

export const createHistoryTemplate = (
  history: IShipmentHistory
): IProgress[] => {
  const { currentState, states } = history
  const isFinalState = checkIfFinalState(currentState)
  const genericState = convertToGenericState(currentState)
  let completionPoint = genericState as number
  const finalState = isFinalState
    ? states.find(state => (state.name as SHIPMENT_STATES) === currentState)
    : undefined

  const progressSteps: IProgress[] = [
    {
      label: ['EXPECTED', 'MADE_REQUEST_FOR_DELIVERY'],
      complete: true,
      state: states.find(state => isExpected(state.name))
    },
    {
      label: ['IN_WAREHOUSE', 'PARCEL_DELIVERED_TO_WAREHOUSE'],
      state: states.find(state => isInWarehouse(state.name))
    },
    {
      label: ['ON_THE_WAY', 'DRIVER_ON_THE_WAY'],
      state: states.find(state => isOnTheWay(state.name))
    },
    {
      label: getFinalStatusLabel(
        genericState,
        get(finalState, 'reasonCode', 'DEFAULT')
      ),
      failure: isGenericFailureState(genericState),
      state: finalState
    }
  ]

  if (isFinalState) {
    if (isDelivered(currentState, false)) {
      completionPoint = 4
    } else {
      const swapIndex = progressSteps.findIndex(
        (progress, index) => !progress.state && ![0, 3].includes(index)
      )

      if (swapIndex > -1) {
        progressSteps[swapIndex] = progressSteps[3]
        progressSteps.splice(
          swapIndex + 1,
          progressSteps.length - (swapIndex + 1)
        )
        completionPoint = swapIndex
      }
    }
  }

  return progressSteps.map((progress, index) => ({
    ...progress,
    complete: completionPoint >= index
  }))
}
