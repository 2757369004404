import * as React from 'react'
import isEqual from 'lodash/isEqual'

import { objectEntries } from '../../../../utils'

import './accordion.scss'

export interface IAccordionProps {
  items: Array<{
    header: any
    content: any
  }>
  selectedItems?: boolean[]
  multipleSelection?: boolean
}

export const initialState: IAccordionProps = {
  items: [],
  selectedItems: [],
  multipleSelection: false
}

export type IAccordionState = Readonly<typeof initialState>

const getNextStateFromProps = (
  nextProps: IAccordionProps,
  prevState: IAccordionState
) => {
  let nextState = {}

  objectEntries(nextProps).forEach(([key, value]) => {
    if (!isEqual(value, prevState[key]) && prevState.hasOwnProperty(key)) {
      nextState = {
        [key]: value
      }
    }
  })

  if (Object.keys(nextState).length > 0) {
    const { items } = nextState as IAccordionState
    const selectedItems = Array.isArray(items)
      ? Array.from(new Array(items.length), () => false)
      : []

    selectedItems[selectedItems.length - 1] = true

    return { ...nextState, selectedItems }
  }

  return null
}

export class Accordion extends React.Component<
  IAccordionProps,
  IAccordionState
> {
  public static getDerivedStateFromProps(
    props: IAccordionProps,
    state: IAccordionState
  ) {
    return getNextStateFromProps(props, state)
  }

  constructor(props: any) {
    super(props)
    const derivedState = getNextStateFromProps(props, initialState)
    this.state = { ...initialState, ...derivedState }
  }

  public toggleContent = (index: number) => () => {
    const { multipleSelection } = this.state
    let { selectedItems } = this.state

    if (selectedItems) {
      if (!multipleSelection) {
        selectedItems = selectedItems.map((val, i) =>
          i === index ? !val : false
        )
      } else {
        selectedItems[index] = !selectedItems[index]
      }

      this.setState({
        selectedItems
      })
    }
  }

  public render() {
    const { items, selectedItems } = this.state

    return (
      <div className="accordion">
        <ul className="accordion-list">
          {items.map((item, index) => {
            const isChecked = selectedItems && selectedItems[index]
            const tabId = `accordion-tab-${index}`
            return (
              <li className="accordion-item" key={`accordion-item-${index}`}>
                <input
                  id={tabId}
                  type="checkbox"
                  onClick={this.toggleContent(index)}
                  checked={isChecked}
                  readOnly={true}
                />
                <label htmlFor={tabId} className="accordion-tab">
                  <div className="row align-items-center h-100">
                    <div className="col-11">{item.header}</div>
                    <div className="col-1">
                      {!isChecked && (
                        <i className="material-icons">arrow_drop_down</i>
                      )}
                    </div>
                  </div>
                </label>
                <div className="accordion-content">{item.content}</div>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }
}
