import * as React from 'react'
import * as moment from 'moment'
import clsx from 'clsx'
import Linkify from 'react-linkify'

import { Message } from '../../../../../../../models'
import { CHAT_USER } from '../../../../../../../constants'

import { TimeTicker } from '../../../../../../base/components'

export const Chat = ({ instance }: { instance: Message }) => {
  const {
    getUserType: user,
    getMessage: message,
    getTimestamp: timestamp
  } = instance
  // @ts-ignore
  const shortTimestamp = moment(timestamp).toISOString()
  const messagePositionClass = user === CHAT_USER.BOT ? 'left' : 'right'
  const classNames = clsx('message', messagePositionClass)

  return (
    <div className="chat-wrapper">
      <div className={classNames}>
        <div className="message-content">
          <div className="content">
            <Linkify>{message}</Linkify>
          </div>
        </div>
      </div>
      <TimeTicker
        className={clsx('timestamp', messagePositionClass)}
        initialTimestamp={shortTimestamp}
      />
    </div>
  )
}
