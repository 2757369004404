import { Conversation } from 'Trunkrs-SDK/dist/models/communications'
import { IAddress } from 'Trunkrs-SDK/dist/models/communications/conversation/ConversationInterfaces'

import { IIdentity, Message, IChatOption } from '../../../models'

export interface IChatBotState {
  initChatBot: (identity: IIdentity) => Promise<void>
  messageHistory: Message[]
  sendMessage: (
    message: string,
    displayText?: string,
    instance?: Conversation | undefined
  ) => Promise<void>
  chatOptions: IChatOption[]
  getChatOptions: () => void
  forAddressChange: boolean
  forNeighbourUpdate: boolean
  changeAddress: (location: IAddress) => Promise<void>
  updateNeighbour: (location: IAddress) => Promise<void>
  resetChatActions: () => void
}

export const initialState: IChatBotState = {
  initChatBot: () => Promise.resolve(),
  messageHistory: [],
  sendMessage: () => Promise.resolve(),
  chatOptions: [],
  getChatOptions: () => null,
  forAddressChange: false,
  forNeighbourUpdate: false,
  changeAddress: () => Promise.resolve(),
  updateNeighbour: () => Promise.resolve(),
  resetChatActions: () => null
}
