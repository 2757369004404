import { rndInt } from '../../../../utils'

const winWidth = window.innerWidth
const winHeight = window.innerHeight

export class ConfettiParticle {
  public maxConfetti: number = 0
  public ctx: CanvasRenderingContext2D | null
  public x = Math.random() * winWidth // x
  public y = Math.random() * winHeight - winHeight // y
  public r = rndInt(16, 53) // radius
  public d = Math.random() * this.maxConfetti + 11
  public color = ''
  public tilt = Math.floor(Math.random() * 33) - 11
  public tiltAngleIncremental = Math.random() * 0.07 + 0.05
  public tiltAngle = 0

  constructor(props: {
    maxConfetti: number
    possibleColors: string[]
    context: CanvasRenderingContext2D | null
  }) {
    this.maxConfetti = props.maxConfetti
    this.color =
      props.possibleColors[
        Math.floor(Math.random() * props.possibleColors.length)
      ]
    this.ctx = props.context
  }

  public draw = () => {
    if (this.ctx) {
      this.ctx.beginPath()
      this.ctx.lineWidth = this.r / 2
      this.ctx.strokeStyle = this.color
      this.ctx.moveTo(this.x + this.tilt + this.r / 3, this.y)
      this.ctx.lineTo(this.x + this.tilt, this.y + this.tilt + this.r / 5)
      return this.ctx.stroke()
    }
  }
}
