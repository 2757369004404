const isNullOrUndefined = (value: any) =>
  value === null || typeof value === 'undefined'

const maybe = (value: any) => ({
  isNothing: () => isNullOrUndefined(value),
  map: (transformer: any) =>
    !isNullOrUndefined(value) ? Maybe.just(transformer(value)) : Maybe.nothing()
})

export const prop = (propName: string) => (obj: any) => obj[propName]
export const append = (appendee: any) => (appendix: any) => appendee + appendix

export const Maybe = {
  just: maybe,
  nothing: () => maybe(null),
  chain: (...fns: any[]) => (input: any) =>
    fns.reduce((output: any, curr: any) => output.map(curr), input)
}
