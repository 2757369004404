import * as React from 'react'

import { ON_BOARDING_FLOW } from '../../../../../constants'
import { Translate } from '../../../../base/components'

import './onboarding.css'

interface IState {
  flowState: number
}

interface IProps {
  hideOnboardingFlow: (e: React.SyntheticEvent) => void
}

export class Onboarding extends React.Component<IProps, IState> {
  public state = {
    flowState: 0
  }
  public handleChange = (currentFlow: number) => () => {
    this.setState(() => ({ flowState: currentFlow }))
  }

  public handlePrev = () => {
    this.setState(state => ({
      flowState: state.flowState - 1
    }))
  }
  public handleNext = () => {
    this.setState(state => ({
      flowState: state.flowState + 1
    }))
  }

  public render() {
    const { flowState } = this.state
    return (
      <div id="on-boarding-container" className="on-boarding-container">
        <div id="on-boarding-flow" className="on-boarding-flow">
          {ON_BOARDING_FLOW.map((value, index) => (
            <div
              id={value.flow}
              key={value.flow}
              className={`on-boarding-content ${
                index === flowState ? 'shown' : ''
              }`}
            >
              <div className={`on-boarding-image ${value.flow}`} />
              <div className="on-boarding-text-1">
                <Translate>{value.text_1}</Translate>
              </div>
              <div className="on-boarding-text-2">
                <Translate>{value.text_2}</Translate>
              </div>
            </div>
          ))}
        </div>
        <div className="on-boarding-nav">
          <div className="on-boarding-button">
            {flowState > 0 && (
              <div className="on-boarding-back" onClick={this.handlePrev}>
                <Translate>ONBOARDING_BACK</Translate>
              </div>
            )}
          </div>
          <div className="on-boarding-dots">
            {ON_BOARDING_FLOW.map((value, index) => (
              <div key={index}>
                <input
                  className="dots radio"
                  type="radio"
                  checked={flowState === index}
                  onChange={this.handleChange(index)}
                />
              </div>
            ))}
          </div>
          <div
            id="on-boarding-button"
            className="on-boarding-button next-ready"
          >
            {flowState < 4 ? (
              <div className="on-boarding-next-ready" onClick={this.handleNext}>
                <Translate>ONBOARDING_NEXT</Translate>
              </div>
            ) : (
              <div
                className="on-boarding-next-ready"
                onClick={this.props.hideOnboardingFlow}
              >
                <Translate>ONBOARDING_READY</Translate>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}
