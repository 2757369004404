export enum LANGUAGES {
  DUTCH = 'nl',
  ENGLISH = 'en',
  GERMAN = 'de',
  'nl' = 'nl',
  'en' = 'en',
  'de' = 'de'
}

export const TRUNKRS_DEFAULT_LANG = 'TRUNKRS_DEFAULT_LANG'
