import * as React from 'react'

import { ConfettiParticle } from './confettiParticle'

import './confetti.scss'

interface IConfettiProps {
  maxConfetti?: number
  possibleColors?: string[]
  duration?: number
}

const winWidth = window.innerWidth
const winHeight = window.innerHeight

export class Confetti extends React.Component<IConfettiProps, {}> {
  public static defaultProps: Partial<IConfettiProps> = {
    maxConfetti: 150,
    possibleColors: [
      'DodgerBlue',
      'OliveDrab',
      'Gold',
      'Pink',
      'SlateBlue',
      'LightBlue',
      'Gold',
      'Violet',
      'PaleGreen',
      'SteelBlue',
      'SandyBrown',
      'Chocolate',
      'Crimson'
    ],
    duration: 5
  }

  public node: HTMLCanvasElement | null = null
  public ctx: CanvasRenderingContext2D | null = null
  public confettis: ConfettiParticle[] = []
  public destroyed: boolean = false
  public tId: any = null

  public drawConfetti = () => {
    const { maxConfetti } = this.props
    const results = []

    if (!this.destroyed) {
      requestAnimationFrame(this.drawConfetti)
    }

    if (this.ctx && maxConfetti) {
      this.ctx.clearRect(0, 0, winWidth, winHeight)

      for (let i = 0; i < maxConfetti; i++) {
        results.push(this.confettis[i].draw())
      }

      let remainingFlakes = 0

      for (let i = 0; i < maxConfetti; i++) {
        const particle = this.confettis[i]

        particle.tiltAngle += particle.tiltAngleIncremental
        particle.y += (Math.cos(particle.d) + 3 + particle.r / 2) / 2
        particle.tilt = Math.sin(particle.tiltAngle - i / 3) * 15

        if (particle.y <= winHeight) {
          remainingFlakes = remainingFlakes + 1
        }

        if (
          particle.x > winWidth + 30 ||
          particle.x < -30 ||
          particle.y > winHeight
        ) {
          particle.x = Math.random() * winWidth
          particle.y = -30
          particle.tilt = Math.floor(Math.random() * 10) - 20
        }
      }
    }

    return results
  }

  public destroy = () => {
    this.destroyed = true
    if (this.node && this.node.parentNode) {
      this.node.parentNode.removeChild(this.node)
    }
    window.clearTimeout(this.tId)
  }

  public componentWillUnmount() {
    this.destroy()
  }

  public componentDidMount() {
    const { maxConfetti = 150, possibleColors = [], duration } = this.props
    this.ctx = this.node!.getContext('2d')

    this.confettis = Array.from(
      new Array(maxConfetti),
      () =>
        new ConfettiParticle({
          maxConfetti,
          possibleColors,
          context: this.ctx
        })
    )

    this.drawConfetti()

    this.tId = setTimeout(() => {
      this.destroy()
    }, (duration || 5) * 1000)
  }

  public attachNode = (elem: any) => {
    if (!elem) {
      return
    }

    this.node = elem
    this.node!.width = winWidth
    this.node!.height = winHeight
  }

  public render() {
    return <canvas className={'confetti'} ref={this.attachNode} />
  }
}
